import { appReleaseVersion } from '@environments';
import {
  browserName,
  isDesktop,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';

function getAppVersion() {
  return appReleaseVersion;
}

function getDeviceType() {
  return isDesktop ? 'desktop' : 'mobile';
}

function getDeviceOS() {
  return `${osName} ${osVersion}`;
}

function getDeviceModel() {
  // cant really tell device model in desktop devices based on the user agent
  return isMobile ? `${mobileVendor} ${mobileModel}` : 'unknown';
}

export function getSiblyClientHeader(): string {
  const appVersion = getAppVersion();
  const deviceType = getDeviceType();
  const deviceOS = getDeviceOS();
  const deviceModel = getDeviceModel();

  return `appVersion: v${appVersion}; clientType: browser; clientSubType: ${browserName}; deviceType: ${deviceType}; deviceOS: ${deviceOS}; deviceModel: ${deviceModel}; isEmulator: false`;
}
