import { environmentSelected } from '@environments';
import { addMilliseconds, differenceInMilliseconds, isAfter } from 'date-fns';
import jwtDecode from 'jwt-decode';

const tokenKey = 'token';
const tokenExpirationKey = 'token-exp';

const clientTokenTimeout = environmentSelected.sessionTimeout;

interface SiblyJwt {
  sub: number;
}

let expirationTimeoutId: ReturnType<typeof setTimeout>;

function clearTokenTimeout() {
  if (expirationTimeoutId) {
    clearTimeout(expirationTimeoutId);
  }
}

export function getToken(): string {
  return window.sessionStorage.getItem(tokenKey) || '';
}

export function getTokenExpirationDate(): Date | null {
  const expirationDateMillis =
    window.sessionStorage.getItem(tokenExpirationKey);

  return expirationDateMillis ? new Date(Number(expirationDateMillis)) : null;
}

export function clearToken() {
  console.log('[TokenProvider] clearing token');
  window.sessionStorage.removeItem(tokenKey);
  window.sessionStorage.removeItem(tokenExpirationKey);
  clearTokenTimeout();
}

function setExpirationTokenTimeout() {
  console.log('[TokenProvider] called setExpirationTokenTimeout');
  clearTokenTimeout();

  const tokenExpirationDate = getTokenExpirationDate();
  if (!tokenExpirationDate) return;

  // get milliseconds remaining before expiration date
  const diffWithExpirationInMillis = differenceInMilliseconds(
    tokenExpirationDate,
    new Date(),
  );

  // remove token after token expiration
  expirationTimeoutId = setTimeout(() => {
    console.log('[TokenProvider] clearing token due to timeout');
    clearToken();
    window.location.pathname = '/session-timeout';
  }, diffWithExpirationInMillis);
}

export function checkIfTokenExpired() {
  console.log('[TokenProvider] adding event listeners');

  const tokenExpirationDate = getTokenExpirationDate();

  // there is no token
  if (!tokenExpirationDate) return;

  if (isAfter(new Date(), tokenExpirationDate)) {
    // token is expired
    console.log('[TokenProvider] session is expired');
    clearToken();
    window.location.pathname = '/session-timeout';
  } else {
    // set timeout to expire token
    setExpirationTokenTimeout();
  }
}

export function addTokenStorageEvent() {
  // will be triggered if session storage is manually modified in devtools
  window.addEventListener('storage', (e) => {
    if (e.storageArea !== window.sessionStorage) return;
    console.log('[TokenProvider] received storage event');

    if (!getToken()) {
      window.location.pathname = '/session-timeout';
    }
  });
}

export function setToken(token: string) {
  const expirationDate = addMilliseconds(new Date(), clientTokenTimeout);

  window.sessionStorage.setItem(tokenKey, token);
  window.sessionStorage.setItem(
    tokenExpirationKey,
    expirationDate.getTime().toString(),
  );

  setExpirationTokenTimeout();
}

export function getUserId(): number | null {
  const token = getToken();
  return token ? jwtDecode<SiblyJwt>(token).sub : null;
}
