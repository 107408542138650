import { AccessCodeModel } from '@stores/models/Eligibility/Home/AccessCodeModel';
import cn from 'classnames';
import { Instance } from 'mobx-state-tree';
import styles from '../../organization-section.module.scss';
import { AutocompleteOption } from './AutocompleteOption';
import { NoResultsFound } from './NoResultsFound';

type Props = {
  values: Instance<typeof AccessCodeModel>[];
  showAutocomplete: boolean;
  highlight?: string;
  onSelectOrganization: (
    selectedAccessCode: string,
    organization: string,
  ) => unknown;
  mobile?: boolean;
};

export function OrganizationsAutocomplete({
  values,
  showAutocomplete,
  onSelectOrganization,
  highlight,
  mobile,
}: Props) {
  return (
    <div
      className={cn(
        styles.autocomplete,
        showAutocomplete && styles.isVisible,
        mobile && styles.autocompleteMobile,
      )}
    >
      <ul className={styles.autocompleteOptionsList}>
        {values.length ? (
          values.map((code) => (
            <AutocompleteOption
              key={code.accessCode}
              value={code.accessCode}
              label={code.organizationName}
              onSelect={onSelectOrganization}
              highlight={highlight}
            />
          ))
        ) : (
          <NoResultsFound />
        )}
      </ul>
    </div>
  );
}

OrganizationsAutocomplete.defaultProps = {
  highlight: undefined,
  mobile: false,
};
