import { SubmitButton } from '@components/Button';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/storeProvider';
import { Toast } from '@components/Toast';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { OrganizationInput } from './OrganizationInput';

import styles from './organization-section.module.scss';
import { OrganizationsAutocomplete } from './OrganizationInput/Autocomplete';

function OrganizationSectionComponent() {
  const {
    eligibility: {
      selectedAccessCode,
      validateOrganizationAccessCode,
      showFakeOrgMessage,
      showFailedToLoadMessage,
      autocompleteValues,
      setSelectedAccessCode,
      showAutocomplete,
      organizationInputValue,
      trackOrgEntered,
      setSelectedEligibilityAccessCode,
    },
  } = useStore();

  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    trackOrgEntered();
    const isValidAccessCode = validateOrganizationAccessCode();

    if (isValidAccessCode) {
      setSelectedEligibilityAccessCode(selectedAccessCode);
      navigate('/eligibility/confirm-coverage');
    }
  }, [
    navigate,
    validateOrganizationAccessCode,
    selectedAccessCode,
    trackOrgEntered,
    setSelectedEligibilityAccessCode,
  ]);

  return (
    <>
      {showFakeOrgMessage && (
        <Toast
          variant="error"
          text="Enrollment for this organization is closed."
          className={styles.enrollmentClosedToast}
        />
      )}
      {showFailedToLoadMessage && (
        <Toast
          variant="error"
          text="Failed to load Organizations data, try again later."
          className={styles.enrollmentClosedToast}
        />
      )}
      <div className={styles.organizationInputContainer}>
        <OrganizationInput />
        <OrganizationsAutocomplete
          values={autocompleteValues}
          onSelectOrganization={setSelectedAccessCode}
          showAutocomplete={showAutocomplete}
          highlight={organizationInputValue}
        />
      </div>
      <SubmitButton
        variant="primary"
        disabled={!selectedAccessCode}
        className={styles.continueOrgButton}
        data-testid="submit-organization"
        onClick={handleSubmit}
      >
        Continue
      </SubmitButton>
    </>
  );
}

export const OrganizationSection = observer(OrganizationSectionComponent);
