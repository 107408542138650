import { Banner } from '@components/Banner';
import { SubmitButton } from '@components/Button';
import { PhoneInput } from '@components/PhoneInput';
import { RadioInput } from '@components/RadioInput';
import { trackEvent } from '@integrations/analytics';
import { captureSentryException } from '@integrations/sentry';
import { HelpSection } from '@pages/Eligibility/HelpSection';
import { VerifyMethod } from '@stores/models/Onboarding/VerifyPhone';
import { useStore } from '@stores/storeProvider';
import { PHONE_VERIFICATION_FAQ } from '@utils/constants';
import { FieldValidity } from '@utils/forms';
import { telNational } from '@utils/inputAutocomplete';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './verify-phone.module.scss';

type VerifyPhoneComponentProps = {
  goToNextStep: () => void;
};

function VerifyPhoneComponent({ goToNextStep }: VerifyPhoneComponentProps) {
  const {
    onboarding: {
      safetyData: { isSafetyFormValid },
      verifyPhone: {
        phoneNumber,
        verifyMethodSelected,
        setVerifyMethodSelected,
        setPhoneNumber,
        phoneNumberErrorCaption,
        isSendCodeDisabled,
        last4Numbers,
        sendCode,
        isSendingCode,
        failedToSendCode,
      },
    },
  } = useStore();

  const navigate = useNavigate();

  const handleMethodChange = useCallback(
    (e) => {
      const {
        target: { id },
      } = e;

      setVerifyMethodSelected(id as VerifyMethod);
    },
    [setVerifyMethodSelected],
  );

  const handlePhoneNumberChange = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;

      setPhoneNumber(value);
    },
    [setPhoneNumber],
  );

  useEffect(() => {
    // if safety data is not completed(this can happen if the user reloads site and volatile state is reseted) redirect to fill again
    if (!isSafetyFormValid) {
      navigate('/onboarding/safetyData');
      return;
    }
    trackEvent('Enter Phone Number Page Displayed', {
      page: 'Enter Phone Number',
      source: 'Onboarding',
      origin: 'web',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (failedToSendCode) {
      trackEvent('Invalid Phone Number Entered', {
        page: 'Enter Phone Number',
        source: 'Onboarding',
        origin: 'web',
      });
    }
  }, [failedToSendCode]);

  const handleNextClick = useCallback(async () => {
    trackEvent('Next Clicked for Verification Code', {
      page: 'Enter Phone Number',
      source: 'Onboarding',
      origin: 'web',
      verificationMethod: verifyMethodSelected,
    });
    try {
      await sendCode();
      goToNextStep();
    } catch (e) {
      captureSentryException(e);
    }
  }, [verifyMethodSelected, sendCode, goToNextStep]);

  return (
    <>
      <Banner show={failedToSendCode} isBelowNavbar variant="error">
        <span>
          We were unable to send a code to phone number ending in -
          {last4Numbers}. Please enter a different phone number.
        </span>
      </Banner>
      <div id={styles.verifyPhoneScreen}>
        <h1 className="header-lg-4 mt-xxs mb-0">Verify your phone</h1>
        <p className="text-lg-2 mt-0 mb-md" data-testid="verify-phone-content">
          This is for added security and will not be visible to your coaching
          team.
        </p>
        <PhoneInput
          id="verify-phone-number"
          label="Phone Number"
          countryCode="US"
          autoComplete="on"
          value={phoneNumber}
          name={telNational}
          showPhoneCode
          onChange={handlePhoneNumberChange}
          validity={
            phoneNumberErrorCaption ? FieldValidity.NOT_VALID : undefined
          }
          validateOnBlur
          caption={phoneNumberErrorCaption}
        />
        <div className={styles.sendMethodContainer}>
          <p className={styles.radioButtonsLabel}>
            How should we send the unique code?
          </p>
          <RadioInput
            id="sms"
            name="verify-method"
            label="SMS (Text Message)"
            onChange={handleMethodChange}
            checked={verifyMethodSelected === 'sms'}
          />
          <RadioInput
            id="call"
            name="verify-method"
            label="Voice Call"
            onChange={handleMethodChange}
            checked={verifyMethodSelected === 'call'}
          />
          <p className={styles.bases}>Message and data rates may apply</p>
        </div>
        <div className="horizontal-separator" />
        <div className={styles.submitContainer}>
          <SubmitButton
            variant="primary"
            disabled={isSendCodeDisabled}
            onClick={handleNextClick}
          >
            {isSendingCode ? 'Sending Code...' : 'Next'}
          </SubmitButton>
        </div>
      </div>
      <HelpSection link={PHONE_VERIFICATION_FAQ} />
    </>
  );
}

export const VerifyPhone = observer(VerifyPhoneComponent);
