import { types } from 'mobx-state-tree';

export const EnterAccessCodeModel = types
  .model({})
  .volatile(() => ({
    accessCodeInputValue: '',
  }))
  .actions((self) => ({
    setAccessCodeInputValue(value: string) {
      self.accessCodeInputValue = value;
    },

    resetAccessCodeValue() {
      self.accessCodeInputValue = '';
    },
  }))
  .views((self) => ({
    get isNextButtonDisabled() {
      return self.accessCodeInputValue.length === 0;
    },
  }));
