import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './button.module.scss';
import { ButtonProps } from './types';

export function Button(
  props: PropsWithChildren<
    ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
  >,
) {
  const { children, variant, className, ...buttonProps } = props;

  const classNames = [
    styles.button,
    variant ? styles[variant] : undefined,
    className,
  ];

  return (
    <button className={cn(classNames)} type="button" {...buttonProps}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  className: undefined,
};
