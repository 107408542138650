import { isLocalhost, environmentSelected } from '@environments';

// dinamic import to download the package only on envs that will use it
const getFSModule = () => import('@fullstory/browser');

// make sure we are not using QA/prod config file while running locally
export const isFullstoryActive = () =>
  environmentSelected.fullStory.active && !isLocalhost();

class FullStoryService {
  private static fullstory: any;

  // inject the module in the static methods
  // or prevent them from executing if fullstory is not active
  private static async runModuleFunction(
    callback: (module: Awaited<ReturnType<typeof getFSModule>>) => unknown,
  ) {
    if (!isFullstoryActive()) {
      console.warn('Fullstory is not active for this environment');
      return;
    }
    if (!FullStoryService.fullstory) {
      FullStoryService.fullstory = await getFSModule();
    }

    callback(FullStoryService.fullstory);
  }

  public static async init(): Promise<void> {
    this.runModuleFunction((module) => {
      module.init({ orgId: environmentSelected.fullStory.orgId });
      // this will be used to differentiate dashboard from member_app in fullstory dashboard
      module.setVars('page', {
        pageName: 'member_webapp',
      });
    });
  }

  public static async identify(userId: string): Promise<void> {
    this.runModuleFunction((module) => {
      module.identify(userId, {
        env: process.env.REACT_APP_ENV,
        displayName: `userId ${userId}`,
      });
    });
  }

  public static async shutdown(): Promise<void> {
    this.runModuleFunction((module) => {
      module.shutdown();
    });
  }
}

export default FullStoryService;
