import { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from '../input.module.scss';

type Props = PropsWithChildren<{
  inputValue: string;
  phoneCode?: string;
}>;

export function PrependFocusedInputContainer({
  children,
  inputValue,
  phoneCode,
}: Props) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!phoneCode) return <>{children}</>;

  return (
    <div
      className={cn(
        styles.prependFocusedInputContainer,
        inputValue.length && styles.hasContent,
      )}
    >
      <div className={styles.prependedToControl} data-testid="input-prepend">
        {phoneCode}
      </div>
      {children}
    </div>
  );
}

PrependFocusedInputContainer.defaultProps = {
  phoneCode: undefined,
};
