import { AxiosRequestConfig } from 'axios';
import { environmentSelected } from '@environments';
import { APITarget, RPCPayload } from './types';
import { siblyAxios } from './siblyAxiosInstance';
import { buildRpcPayload } from './buildRpcPayload';
import { headersBuilder, HeadersBuilderOptions } from './headersBuilder';

export type RPCCallParams<Method> = {
  method: Method;
  params?: Record<string, any>;
  headersOptions?: HeadersBuilderOptions;
};

export function rpcCall<Method>(apiTarget: APITarget) {
  return async function requestRPC(rpcParams: RPCCallParams<Method>) {
    // grab the right field for the given target, for the current environment
    const { method, params = {}, headersOptions } = rpcParams;

    try {
      const { host } = environmentSelected.sibly[apiTarget];
      const payload = buildRpcPayload<Method>(method, params);

      const requestConfig: AxiosRequestConfig<RPCPayload<Method>> = {};

      requestConfig.headers = headersBuilder(headersOptions);

      const response = await siblyAxios.post(`${host}`, payload, requestConfig);
      return response.data.result;
    } catch (error) {
      console.error(`request failed for method: ${method}`, error);
      throw error;
    }
  };
}
