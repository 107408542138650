import {
  types,
  SnapshotIn,
  SnapshotOut,
  flow,
  Instance,
} from 'mobx-state-tree';
import { clearToken } from '@services/TokenProvider';
import { SignUp } from './models/SignUp';
import { Onboarding } from './models/Onboarding';
import {
  getDisposer,
  enableSaving,
  applyDataFromSessionStorage,
} from './withStorage';
import { EligibilityModel } from './models/Eligibility/Home/EligibilityModel';
import { ConfirmEligibilityCoverageModel } from './models/Eligibility/ConfirmEligibilityCoverage';
import { EnterAccessCodeModel } from './models/Eligibility/EnterAccessCode/EnterAccessCodeModel';

const autoSave = true;

export const RootStore = types
  .model({
    signUp: SignUp,
    onboarding: Onboarding,
    eligibility: EligibilityModel,
    confirmEligibilityCoverage: ConfirmEligibilityCoverageModel,
    enterAccessCodeEligiblity: EnterAccessCodeModel,
    bootstrapped: types.boolean,
  })
  .actions((self) => ({
    afterBootstrap() {
      console.log('stored bootstrapped');
    },
    hasBootstrapped() {
      return self.bootstrapped;
    },
    setBootstrapped(value: boolean) {
      self.bootstrapped = value;
    },

    finishOnboarding() {
      // clear session token
      clearToken();

      // reset onboarding data
      self.onboarding.resetOnboardingState();
      self.signUp.resetSignUpState();

      // reset eligibility store
      self.eligibility.resetEligibilityState();
      self.confirmEligibilityCoverage.resetConfirmEligibilityCoverageState();
    },

    load: flow(function* load() {
      // if store has already been started return it
      if (self.bootstrapped) {
        return self;
      }

      // load data from sessionStorage
      const data = yield applyDataFromSessionStorage(self);

      // start saving store changes to sessionStorage
      if (autoSave) {
        enableSaving(self);
      }

      // @ts-ignore
      self.afterBootstrap();

      return data;
    }),

    restartSignupState() {
      self.signUp = SignUp.create();
    },

    beforeDetach() {
      const disposer = getDisposer();
      if (disposer) disposer();
    },
  }));

export type RootStoreIn = SnapshotIn<typeof RootStore>;
export type RootStoreOut = SnapshotOut<typeof RootStore>;
export type RootStoreInstance = Instance<typeof RootStore>;
