import { ReactComponent as GetHelpIcon } from '@assets/getHelp.svg';
import { MouseEventHandler } from 'react';
import styles from './help-section.module.scss';

type Props = {
  onClick?: MouseEventHandler;
  link: string;
};

export function HelpSection({ link, onClick }: Props) {
  return (
    <div className={styles.getHelpSection}>
      <p>Having trouble? We&apos;re here for you</p>
      <p>
        <a
          onClick={onClick}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.getHelpLink}
        >
          <GetHelpIcon />
          <span>Get Help</span>
        </a>
      </p>
    </div>
  );
}

HelpSection.defaultProps = {
  onClick: undefined,
};
