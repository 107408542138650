import styles from '../../organization-section.module.scss';

export function NoResultsFound() {
  return (
    <div
      className={styles.noResultsFoundContainer}
      data-testid="autocomplete-no-results-found"
    >
      <span className={styles.header}>0 results found</span>
      <div className={styles.content}>
        <span>
          Can&apos;t find your organization? Check your spelling or{' '}
          <a
            target="_blank"
            href="https://sibly.app.link/e/Eligibility_Get_Help"
            rel="noreferrer"
          >
            contact us
          </a>
        </span>
      </div>
    </div>
  );
}
