import { DateInput } from '@components/DateInput';
import { FieldValidity } from '@utils/forms';
import { isSameDay } from 'date-fns';
import {
  ComponentProps,
  FocusEventHandler,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { isMobile } from 'react-device-detect';
import styles from './datepicker.module.scss';

type Props = {
  id: string;
  label: string;
  formattedDate: string;
  validity?: FieldValidity;
  validateOnBlur?: boolean;
  caption?: string;
  name?: string;
  autocomplete?: boolean;
  onChange: (selected: Date) => unknown;
} & ComponentProps<typeof ReactDatePicker>;

export function DatePicker({
  id,
  label,
  formattedDate,
  validity,
  caption,
  name,
  autocomplete,
  onChange,
  ...datepickerProps
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<null | Date>(null);

  const toggleVisibility = useCallback(() => {
    setIsOpen((curr) => !curr);
  }, []);

  const getDayClassName = useCallback(
    (date: Date) => {
      if (!selectedDate) return null;
      return isSameDay(date, selectedDate) ? styles.selectedDate : null;
    },
    [selectedDate],
  );

  const handleChange = useCallback(
    (selected: Date) => {
      setSelectedDate(selected);
      toggleVisibility();
      onChange(selected);
    },
    [onChange, toggleVisibility],
  );

  const CustomInput = forwardRef((__, ref) => (
    <DateInput
      id={`datepicker-input__${id}`}
      label={label}
      value={formattedDate}
      validity={validity}
      caption={caption}
      name={name}
      autoComplete={autocomplete ? 'on' : undefined}
      onClick={toggleVisibility}
      readOnly
      withCalendarButton
      handleCalendarClick={toggleVisibility}
      onFocus={
        toggleVisibility as unknown as FocusEventHandler<HTMLInputElement>
      }
      // @ts-ignore
      inputRef={ref}
    />
  ));

  CustomInput.displayName = 'CustomInput';

  return (
    <ReactDatePicker
      open={isOpen}
      showPopperArrow={false}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dayClassName={getDayClassName}
      onClickOutside={toggleVisibility}
      onChange={handleChange}
      maxDate={new Date()}
      // @ts-ignore
      customInput={<CustomInput />}
      withPortal={isMobile}
      {...datepickerProps}
    />
  );
}

DatePicker.defaultProps = {
  validity: FieldValidity.NOT_VALIDATED,
  validateOnBlur: false,
  name: undefined,
  caption: undefined,
  autocomplete: false,
};
