import { SnapshotIn, types } from 'mobx-state-tree';

export const AccessCodeModel = types.model({
  accessCode: types.identifier,
  isSiblyClient: types.boolean,
  organizationId: types.number,
  organizationName: types.string,
});

export type AccessCodeModelIn = SnapshotIn<typeof AccessCodeModel>;
