import { trackEvent } from '@integrations/analytics';
import { captureSentryException } from '@integrations/sentry';
import { isAPIError } from '@services/SiblyAPI/isAPIError';
import { getMe, updateMember } from '@services/SiblyAPI/platform';
import { getUserId } from '@services/TokenProvider';
import { flow, types } from 'mobx-state-tree';

type VolatileState = {
  preferredName: string;
  isSavingPreferredName: boolean;
};

const initialVolatile = {
  preferredName: '',
  isSavingPreferredName: false,
};

export const OnboardingPreferredNameModel = types
  .model({})
  .volatile<VolatileState>(() => initialVolatile)
  .actions((self) => ({
    setPreferredName(name: string) {
      self.preferredName = name;
    },
  }))
  .actions((self) => ({
    updateDisplayName: flow(function* updateDisplayName() {
      try {
        self.isSavingPreferredName = true;
        const trimmedPreferredName = self.preferredName.trim();
        const member = yield updateMember({ name: trimmedPreferredName });
        trackEvent('Name Entered');
        return member;
      } catch (exception) {
        captureSentryException(exception);
        let errorMessage = 'Something went wrong. Please try again later.';

        if (
          isAPIError(exception) &&
          exception.response?.status &&
          exception.response.status < 500
        ) {
          errorMessage = exception.response?.data.error.message;
        }

        throw new Error(errorMessage);
      } finally {
        self.isSavingPreferredName = false;
      }
    }),

    getMemberData: flow(function* fetchDisplayName() {
      const memberId = getUserId();
      if (!memberId) return;

      const me = yield getMe();

      if (me.name) {
        self.setPreferredName(me.name);
      }
    }),

    reset() {
      self.setPreferredName('');
    },
  }))
  .views((self) => ({
    get isPreferredNameValid(): boolean {
      const trimmed = self.preferredName.trim();
      return trimmed.length > 1 && trimmed.length < 31;
    },

    get preferredNameErrorCaption() {
      if (this.isPreferredNameValid) {
        return undefined;
      }

      const trimmedName = self.preferredName.trim();

      if (trimmedName.length === 0) {
        return 'You must have a visible display name';
      }

      if (trimmedName.length < 2 || trimmedName.length > 30) {
        return 'Your username must be at least 2 characters and 30 characters max';
      }

      return undefined;
    },
  }));
