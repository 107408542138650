import { qa } from './qa';
import { Environment } from './types';

export const local: Environment = {
  ...qa,
  name: 'local',
  sibly: {
    platform: {
      host: 'http://localhost:5000',
    },

    safetyData: {
      host: 'http://localhost:8001',
    },
  },

  fullStory: {
    orgId: '182P5Q',
    active: false,
  },

  oneSignal: {
    appId: '3b01dbba-2f28-426f-a4da-bde35e3abb73',
  },
};
