import { useAccesibilityEvents } from '@utils/hooks/useAccesibilityEvents';
import { useCallback, useMemo } from 'react';
import styles from '../../organization-section.module.scss';

type Props = {
  label: string;
  value: string;
  onSelect: (selection: string, label: string) => unknown;
  highlight?: string;
};

/**
 *
 * @param highlight the part of the string to highlight
 * @param value the input string
 * @returns an array containing the highlighted part of the string in the first position and the rest of it in the second position
 */
function highlightSubstring(highlight: string, value: string) {
  const regexp = new RegExp(`^(${highlight})(.*)`, 'i');
  const match = value.match(regexp);

  return match && [match[1], match[2]];
}

export function AutocompleteOption({
  label,
  value,
  onSelect,
  highlight,
}: Props) {
  const handleSelect = useCallback(() => {
    onSelect(value, label);
  }, [onSelect, value, label]);

  const { onClick, onKeyDown } = useAccesibilityEvents(handleSelect);

  const textWithHighlight = useMemo(
    () => !!highlight && highlightSubstring(highlight, label),
    [highlight, label],
  );

  return (
    <li
      className={styles.autocompleteOption}
      data-testid={`auto-option-${label}`}
    >
      <div role="button" onClick={onClick} onKeyDown={onKeyDown} tabIndex={0}>
        {textWithHighlight ? (
          <span>
            <b>{textWithHighlight[0]}</b>
            {textWithHighlight[1]}
          </span>
        ) : (
          <span>{label}</span>
        )}
      </div>
    </li>
  );
}

AutocompleteOption.defaultProps = {
  highlight: undefined,
};
