import { Button } from '@components/Button';
import { useStore } from '@stores/storeProvider';
import { observer } from 'mobx-react-lite';

import styles from './eligibility-home.module.scss';
import { OrganizationInput } from './OrganizationSection/OrganizationInput';
import { OrganizationsAutocomplete } from './OrganizationSection/OrganizationInput/Autocomplete';

function MobileOrganizationInputComponent() {
  const {
    eligibility: {
      clearOrganizationInput,
      autocompleteValues,
      setSelectedAccessCode,
      showAutocomplete,
      organizationInputValue,
    },
  } = useStore();

  return (
    <div
      className={styles.mobileOrganizationInputContainer}
      data-testid="mobile-organization-input"
    >
      <div className={styles.inputRow}>
        <div className={styles.searchInput}>
          <OrganizationInput focusOnMount />
        </div>
        <Button
          variant="secondary"
          className={styles.cancelButton}
          onClick={clearOrganizationInput}
        >
          Cancel
        </Button>
      </div>
      <OrganizationsAutocomplete
        values={autocompleteValues}
        onSelectOrganization={setSelectedAccessCode}
        showAutocomplete={showAutocomplete}
        highlight={organizationInputValue}
        mobile
      />
    </div>
  );
}

export const MobileOrganzationInput = observer(
  MobileOrganizationInputComponent,
);
