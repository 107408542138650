import { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './select.module.scss';
import type { SelectProps } from '.';

export function InnerLabelContainer(
  props: PropsWithChildren<
    Pick<SelectProps, 'id' | 'name' | 'selectedOption' | 'labelPosition'>
  >,
) {
  const containerClasses = [
    styles.innerLabelContainer,
    props.labelPosition === 'inner' && styles.hasInnerLabel,
    props.selectedOption && styles.hasContent,
  ].filter(Boolean);

  const InnerLabel =
    props.labelPosition === 'inner' ? (
      <label htmlFor={props.id} className={styles.innerLabel}>
        {props.name}
      </label>
    ) : null;

  return props.labelPosition === 'inner' ? (
    <div className={cn(containerClasses)}>
      {InnerLabel}
      <span>{props.selectedOption?.label}</span>
    </div>
  ) : (
    <span>{props.selectedOption?.label || 'Select...'}</span>
  );
}
