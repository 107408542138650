import { FieldValidity } from '@utils/forms';
import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as ArrowDown } from '@assets/selectArrowDown.svg';
import { ReactComponent as ArrowUp } from '@assets/selectArrowUp.svg';
import { useAccesibilityEvents } from '@utils/hooks/useAccesibilityEvents';
import { useClickOutside } from '@utils/hooks/useClickOutside';
import styles from './select.module.scss';
import { SelectDropdown, SelectOption } from './SelectDropdown';
import { InnerLabelContainer } from './InnerLabelContainer';

export type SelectProps = {
  id: string;
  name: string;
  validity?: FieldValidity;
  selectedOption?: SelectOption;
  labelPosition?: 'top' | 'inner';
  className?: string;
  handleSelectOption: (optionSelected: SelectOption) => unknown;
  options: SelectOption[];
};

export function Select(props: SelectProps) {
  const {
    id,
    name,
    validity,
    selectedOption,
    options,
    handleSelectOption,
    labelPosition,
    className,
  } = props;

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const selectContainerClasses = useMemo(
    () => [
      styles.selectContainer,
      validity === FieldValidity.VALID && styles.success,
      validity === FieldValidity.NOT_VALID && styles.error,
    ],
    [validity],
  );

  const handleSelectClick = useCallback((e) => {
    // stops propagation if arrow is clicked
    e.stopPropagation();
    setIsSelectOpen((isOpen) => !isOpen);
  }, []);

  const Arrow = useCallback(
    () =>
      isSelectOpen ? (
        <ArrowUp onClick={handleSelectClick} />
      ) : (
        <ArrowDown onClick={handleSelectClick} />
      ),
    [isSelectOpen, handleSelectClick],
  );

  const handleCloseSelect = useCallback(() => {
    setIsSelectOpen(false);
  }, []);

  const onSelectOption = useCallback(
    (selected: SelectOption) => {
      setIsSelectOpen(false);
      handleSelectOption(selected);
    },
    [handleSelectOption],
  );

  const { onClick, onKeyDown } = useAccesibilityEvents(handleSelectClick);

  const TopLabel = useMemo(
    () => (labelPosition === 'top' ? <label htmlFor={id}>{name}</label> : null),
    [labelPosition, name, id],
  );

  const selectRef = useClickOutside<HTMLDivElement>(handleCloseSelect);

  return (
    <div className={className}>
      {TopLabel}
      <div className={styles.selectPositionContainer} ref={selectRef}>
        <div
          className={cn(selectContainerClasses)}
          onClick={onClick}
          onKeyDown={onKeyDown}
          role="combobox"
          aria-expanded={isSelectOpen}
          aria-controls={`select-dropdown-${id}`}
          tabIndex={0}
        >
          <InnerLabelContainer
            id={id}
            name={name}
            labelPosition={labelPosition}
            selectedOption={selectedOption}
          />
          <Arrow />
        </div>
        <SelectDropdown
          id={`select-dropdown-${id}`}
          isVisible={isSelectOpen}
          options={options}
          selectedOption={selectedOption}
          onSelectOption={onSelectOption}
        />
      </div>
    </div>
  );
}

Select.defaultProps = {
  validity: FieldValidity.NOT_VALIDATED,
  selectedOption: undefined,
  className: undefined,
  labelPosition: 'inner',
};
