import { ReactComponent as CalendarIcon } from '@assets/calendar.svg';
import { useAccesibilityEvents } from '@utils/hooks/useAccesibilityEvents';
import { MouseEventHandler } from 'react';

type Props = {
  onClick: MouseEventHandler;
};

export function CalendarButton({ onClick }: Props) {
  const { onClick: onClickAccessible, onKeyDown } =
    useAccesibilityEvents(onClick);

  return (
    <CalendarIcon
      onClick={onClickAccessible}
      onKeyDown={onKeyDown}
      tabIndex={0}
      aria-label="clear input"
    />
  );
}
