import {
  useMemo,
  useState,
  ChangeEvent,
  FocusEventHandler,
  useEffect,
} from 'react';
import cn from 'classnames';
import { FieldValidity } from '@utils/forms';
import { InnerLabelContainer } from './InnerLabelContainer';
import { InputContainer } from './InputContainer';
import { defaultProps, InputProps } from './types';
import { ViewPasswordIcon } from './ViewPasswordIcon';
import styles from './input.module.scss';
import { ReactComponent as GreenTick } from './green-tick.svg';
import { LeftIconContainer } from './LeftIconContainer';
import { ClearInputButton } from './ClearInputButton';
import { CalendarButton } from './CalendarButton';
import { PrependFocusedInputContainer } from './PrependFocusedInputContainer';

export function Input(props: InputProps) {
  const {
    id,
    label,
    onChange,
    onBlur,
    validateOnMount,
    validateOnBlur,
    type,
    labelPosition,
    caption,
    validity,
    className,
    inputRef,
    inputContainerClassName,
    inputContainerRef,
    inputTestId,
    withSearchIcon,
    phoneCode,
    withClearButton,
    withCalendarButton,
    handleClearInput,
    handleCalendarClick,
    ...inputProps
  } = props;
  const [viewPassword, setViewPassword] = useState(false);
  const [value, setValue] = useState((props.value as string) || '');
  const [isValidationEnabled, setIsValidationEnabled] =
    useState(validateOnMount);

  function toggleViewPassword() {
    setViewPassword((isVisible) => !isVisible);
  }

  function handleInputValueChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    if (validateOnMount === false) setIsValidationEnabled(true);
    if (onChange) onChange(e);
  }

  useEffect(() => {
    if (!props.value) return;
    if (validateOnMount === false) setIsValidationEnabled(true);
    setValue(props.value as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (validateOnBlur) setIsValidationEnabled(true);
    if (onBlur) onBlur(e);
  };

  const inputType = useMemo(() => {
    if (type === 'password') {
      return viewPassword ? 'text' : 'password';
    }
    return type;
  }, [type, viewPassword]);

  const TopLabel = useMemo(
    () =>
      labelPosition === 'top' ? (
        <div className={styles.topLabelContainer}>
          <label htmlFor={id}>{label}</label>
        </div>
      ) : null,
    [labelPosition, label, id],
  );

  const Caption = useMemo(
    () =>
      isValidationEnabled && caption ? (
        <span
          className={cn(
            'form-control-caption',
            validity === FieldValidity.NOT_VALID && styles.captionError,
          )}
          data-testid={`input-caption-${label}`}
        >
          {caption}
        </span>
      ) : null,
    [caption, validity, label, isValidationEnabled],
  );

  return (
    <div className={className} ref={inputContainerRef}>
      {TopLabel}
      <InputContainer
        inputContainerClassName={inputContainerClassName}
        validity={isValidationEnabled ? validity : FieldValidity.NOT_VALIDATED}
      >
        <LeftIconContainer withSearchIcon={withSearchIcon} />
        <InnerLabelContainer
          labelPosition={labelPosition}
          id={id}
          label={label}
          inputValue={value}
        >
          <PrependFocusedInputContainer
            phoneCode={phoneCode}
            inputValue={value}
          >
            <input
              {...inputProps}
              ref={inputRef}
              type={inputType}
              className={styles.input}
              id={id}
              onChange={handleInputValueChange}
              onBlur={handleInputBlur}
              aria-invalid={
                validity === FieldValidity.NOT_VALID ? 'true' : 'false'
              }
              data-testid={inputTestId}
            />
          </PrependFocusedInputContainer>
        </InnerLabelContainer>
        {validity === FieldValidity.VALID ? (
          <div className={styles.greenTick}>
            <GreenTick />
          </div>
        ) : null}
        {type === 'password' ? (
          <ViewPasswordIcon
            viewPassword={viewPassword}
            onClick={toggleViewPassword}
          />
        ) : null}
        {withClearButton && handleClearInput && (
          <ClearInputButton onClear={handleClearInput} />
        )}
        {withCalendarButton && handleCalendarClick && (
          <CalendarButton onClick={handleCalendarClick} />
        )}
      </InputContainer>
      {Caption}
    </div>
  );
}

Input.defaultProps = defaultProps;
