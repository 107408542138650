import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { FieldValidity } from '@utils/forms';
import styles from '../input.module.scss';
import { InputProps } from '../types';

type Props = PropsWithChildren<
  Pick<InputProps, 'validity' | 'inputContainerClassName'>
>;
export function InputContainer(props: Props) {
  const borderClasses = [
    styles.inputContainer,
    props.inputContainerClassName,
    props.validity === FieldValidity.VALID && styles.success,
    props.validity === FieldValidity.NOT_VALID && styles.error,
  ].filter(Boolean);

  return <div className={cn(borderClasses)}>{props.children}</div>;
}

InputContainer.defaultProps = {
  validity: FieldValidity.NOT_VALIDATED,
  inputContainerClassName: undefined,
};
