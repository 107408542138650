import { useSplitBooleanTreatment } from '@integrations/split';
import { getOnboardingSteps } from '@stores/models/Onboarding';
import { useEffect, useMemo, useRef } from 'react';

type Options = {
  handleOnboardingStepsChange?: Function;
};

export function useOnboardingSteps(options?: Options) {
  const { enabled: isPhoneVerificationEnabled } = useSplitBooleanTreatment(
    'PHONE_VERIFICATION_ENABLED',
  );

  const wasEnabled = useRef(isPhoneVerificationEnabled);

  const onboardingSteps = useMemo(
    () => getOnboardingSteps({ isPhoneVerificationEnabled }),
    [isPhoneVerificationEnabled],
  );

  useEffect(() => {
    if (wasEnabled.current !== isPhoneVerificationEnabled) {
      wasEnabled.current = isPhoneVerificationEnabled;
      options?.handleOnboardingStepsChange?.(onboardingSteps);
    }
  }, [isPhoneVerificationEnabled, onboardingSteps, options]);

  return onboardingSteps;
}
