import QRCode from '@common/OnboardingQRCode/QRCode';
import { environmentSelected } from '@environments';
import { useStore } from '@stores/storeProvider';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import styles from './session-timeout.module.scss';

function SessionTimeout() {
  const { finishOnboarding } = useStore();

  useEffect(() => {
    finishOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper} data-testid="session-timeout">
      <h1>Your session timed out</h1>
      <h3>Please continue your sign up process in the Sibly app</h3>
      <h5>
        Get it by scanning this QR code or visiting:{' '}
        <a
          href={environmentSelected.onboarding.appDownloadLink}
          target="_blank"
          rel="noreferrer"
        >
          {environmentSelected.onboarding.appDownloadLink}
        </a>
      </h5>
      <QRCode qrCodeName={environmentSelected.onboarding.onboardingQRName} />
    </div>
  );
}

export default observer(SessionTimeout);
