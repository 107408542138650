export const orgFamilyNames: Record<string, RegExp> = {
  // "WW" or "W.W" or "WW, Inc." or "W.W, Inc." or "Weight watchers"
  'WW INC': /^(W.?W(, Inc\.)?|Weight Watchers)$/i,
  // "Rivian Automotive Inc" or "Rivian Automotive, Inc"
  Rivian: /^Rivian Automotive,? Inc$/i,
  'National Debt Relief': /^NDR$/i,
  Merit: /^MRT$/i,
  'Hobbs Inc': /^Hobbs$/i,
  'Zeller Realty Group': /^ZRG$/i,
  // "CHI" or "CHI Overhead" or "CHI Overhead Doors"
  'KKR - C.H.I. Overhead Doors': /CHI( Overhead( Doors)?)?$/i,
  // "CPP" or "Covenant" or "Covenant physician partners"
  'KKR - Covenant Physician Partners':
    /^(C\.?P\.?P)|Covenant( physician partners)?$/i,
};
