import cn from 'classnames';
import { useMemo } from 'react';
import styles from './toast.module.scss';

import { ReactComponent as ErrorIcon } from './assets/ErrorIcon.svg';

type ToastProps = {
  variant?: 'default' | 'success' | 'error' | 'info' | 'warn';
  className?: string;
  text: string;
};

export function Toast({ variant, className, text }: ToastProps) {
  const classes = [styles.toast, styles[`toast-${variant}`], className].filter(
    Boolean,
  );

  const Icon = useMemo(() => {
    switch (variant) {
      case 'error':
        return ErrorIcon;
      // add more Icons as needed
      default:
        return null;
    }
  }, [variant]);

  return (
    <div role="alert" className={cn(classes)} data-testid={`toast-${variant}`}>
      {Icon && (
        <Icon
          className={styles.icon}
          aria-hidden="true"
          focusable="false"
          data-testid="toast-icon"
        />
      )}
      <span data-testid="toast-text">{text}</span>
    </div>
  );
}

Toast.defaultProps = {
  variant: 'default',
  className: undefined,
};
