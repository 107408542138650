import { IS_PROD } from '@environments';
import { Instance } from 'mobx-state-tree';
import { createContext, ReactElement, useContext } from 'react';
import { ConfirmEligibilityCoverageModel } from './models/Eligibility/ConfirmEligibilityCoverage';
import { EnterAccessCodeModel } from './models/Eligibility/EnterAccessCode/EnterAccessCodeModel';
import { EligibilityModel } from './models/Eligibility/Home/EligibilityModel';
import { Onboarding } from './models/Onboarding';
import { SignUp } from './models/SignUp';
import { RootStore, RootStoreIn } from './Root';

const initialValue: RootStoreIn = {
  signUp: SignUp.create(),
  onboarding: Onboarding.create({
    preferredName: {},
    safetyData: {},
    verifyPhone: {},
  }),
  // @ts-ignore
  eligibility: EligibilityModel.create({
    accessCodes: {},
  }),
  confirmEligibilityCoverage: ConfirmEligibilityCoverageModel.create(),
  enterAccessCodeEligiblity: EnterAccessCodeModel.create(),
  bootstrapped: false,
};

const Store = RootStore.create(initialValue);

const bootstrap = async () => {
  try {
    await Store.load();
  } catch (error) {
    const errorMessage = 'Error during initialization!';
    console.error(errorMessage, error);

    // if there is problem let the router take over
    Store.setBootstrapped(true);
  }
};

bootstrap();

// add store to the window object to easily debug app state in dev mode
if (!IS_PROD) {
  window.Store = Store;
}

export const ApplicationStoreContext = createContext<Instance<
  typeof RootStore
> | null>(null);

const { Provider } = ApplicationStoreContext;

export function StoreProvider({ children }: { children: ReactElement }) {
  return <Provider value={Store}>{children}</Provider>;
}

export function useStore() {
  const storeInstance = useContext(ApplicationStoreContext);
  if (storeInstance === null) {
    // this means the MSTProvider is not a parent of the component using this hook and that is very bad
    throw new Error(
      'Store is null in MSTProvider, is it instantiated near the top of the app component hierarchy?',
    );
  }
  return storeInstance;
}
