import { ReactComponent as TestQRCode } from './assets/qr-code-test.svg';
import { ReactComponent as ProductionQRCode } from './assets/qr-code-production.svg';
import { Environment } from '../../environments/types';

export const QrCodeComponent = {
  test: TestQRCode,
  production: ProductionQRCode,
};

type QRCodeProps = {
  qrCodeName: Environment['onboarding']['onboardingQRName'];
  className?: string;
};

export default function QRCode({ qrCodeName, className }: QRCodeProps) {
  const Component = QrCodeComponent[qrCodeName];
  return (
    <Component className={className} data-testid={`qr-code-${qrCodeName}`} />
  );
}

QRCode.defaultProps = {
  className: undefined,
};
