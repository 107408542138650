export function formatDate(phoneNumber: string) {
  // extract all numbers from input value
  // and join them in a string
  const numberGroups = phoneNumber.match(/\d+/g);
  if (!numberGroups) return phoneNumber;
  const joinned = numberGroups.join('');

  // for `1` returns `1`
  if (joinned.length < 2) return joinned;

  // for 123 returns 12/3
  if (joinned.length < 4)
    return `${joinned.substring(0, 2)}/${joinned.substring(2, 4)}`;

  // for 12091950 returns 12/09/1950
  return `${joinned.substring(0, 2)}/${joinned.substring(
    2,
    4,
  )}/${joinned.substring(4)}`;
}
