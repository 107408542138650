import { InputProps } from '@components/Input/types';
import { MaskedInput } from '@components/MaskedInput';
import { ChangeEvent, KeyboardEventHandler, useRef } from 'react';
import { formatDate } from './formatDate';

export function DateInput(props: InputProps) {
  const stoppedPropagation = useRef(false);

  // apply date formatting to input and call onChange prop
  const handleDateInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (stoppedPropagation.current) {
      if (props.onChange) {
        props.onChange(e);
      }
      stoppedPropagation.current = false;
      return;
    }
    const formatted = formatDate(e.target.value);
    e.target.value = formatted;
    if (props.onChange) {
      props.onChange(e);
    }
  };

  // prevent adding mask if backspace is pressed
  const handleDateInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    e,
  ) => {
    if (e.code !== 'Backspace') return;
    stoppedPropagation.current = true;
  };

  return (
    <MaskedInput
      {...props}
      onChange={handleDateInputChange}
      onKeyDown={handleDateInputKeyDown}
    />
  );
}
