import { MaskWrapper } from '@common/FullstoryMask/FullStoryMask';
import { Input } from '@components/Input';
import { ComponentProps } from 'react';

type MaskedInputProps = ComponentProps<typeof Input>;

export function MaskedInput(props: MaskedInputProps) {
  return (
    <MaskWrapper>
      <Input {...props} />
    </MaskWrapper>
  );
}
