import React, { Suspense, useEffect } from 'react';
import cn from 'classnames';
import { isAndroid } from 'react-device-detect';

import { useBreakpoints } from '@utils/hooks/useBreakpoints';
import { environmentSelected } from '@environments';
import { OnboardingQRCode } from '@common/OnboardingQRCode';
import { trackEvent } from '@integrations/analytics';
import { Navbar } from '@components/Navbar';
import { Footer } from '@components/Footer';
import styles from './get-the-app.module.scss';

import { ReactComponent as AppStoreLink } from './assets/app-store-icon.svg';
import { ReactComponent as PlayStoreLink } from './assets/play-store-icon.svg';
import { ReactComponent as ScanMeBubble } from './assets/scan-me-bubble.svg';

const PhoneCapture = React.lazy(() => import('./PhoneCapture'));

export function GetTheApp() {
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    trackEvent('Get the App Page Displayed');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isMobile) {
    return (
      <div className={styles.getTheApp}>
        <Navbar />
        <div className={styles.contentContainer}>
          <div
            className={styles.desktopContainer}
            data-testid="get-the-app-desktop"
          >
            <div className={styles.desktopDescriptionContainer}>
              <div className={styles.desktopContainerHeadline} />
              <h1>Start talking with your coach today</h1>
              <h3>
                Scan the QR code with your camera to download the app on your phone or tablet.
              </h3>
            </div>
            <div>
              <OnboardingQRCode
                aria-label="download app qr code"
                className={styles.qrCode}
              />
              <ScanMeBubble className={styles.scanMeBubble} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className={styles.getTheApp}>
      <Navbar />
      <div className={styles.contentContainer}>
        <div
          className={styles.mobile}
          data-testid="get-the-app-mobile-container"
        >
          <h1 className={cn('header-sm-3', styles.mobileTitle)}>
            Get Sibly on your phone
          </h1>
          <h3 className={cn('text-sm-2', styles.mobileText)}>
            Download the Sibly app on the App Store or Google Play store.
          </h3>
          <div className={styles.mobileLink}>
            <a href={environmentSelected.onboarding.appDownloadLink}>
              {isAndroid ? (
                <PlayStoreLink
                  data-testid="get-the-app-play-store"
                  aria-label="download from play store"
                />
              ) : (
                <AppStoreLink
                  data-testid="get-the-app-ios-store"
                  aria-label="download from app store"
                />
              )}
            </a>
          </div>
          <Suspense fallback={<br />}>
            <PhoneCapture className={styles.phoneCapture} />
          </Suspense>
        </div>
      </div>
      <Footer />
    </div>
  );
}
