import { birthday, familyName, givenName } from '@utils/inputAutocomplete';

export const confirmCoverageFirstNameInputName = givenName;
export const confirmCoverageLastNameInputName = familyName;
export const confirmCoverageBirthdayInputName = birthday;

export type ConfirmCoverageInputName =
  | typeof confirmCoverageFirstNameInputName
  | typeof confirmCoverageLastNameInputName
  | typeof confirmCoverageBirthdayInputName;
