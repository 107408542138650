import cn from 'classnames';
import styles from './accordion.module.scss';
import { AccordionItem } from './AccordionItem';

type AccordionProps = {
  items: { title: string; content: string; id: string }[];
  itemClassName?: string;
  className?: string;
};

export function Accordion({ items, className, itemClassName }: AccordionProps) {
  return (
    <div className={cn(styles.accordion, className)}>
      {items.map((item) => (
        <AccordionItem
          {...item}
          className={itemClassName}
          id={item.id}
          key={item.id}
        />
      ))}
    </div>
  );
}

Accordion.defaultProps = {
  itemClassName: undefined,
  className: undefined,
};
