import { getToken } from '@services/TokenProvider';
import { getSiblyClientHeader } from './userAgentHeader';

export type CustomHeaders = {
  Authorization?: string;
  'sibly-client'?: string;
};

export type HeadersBuilderOptions = {
  includeAuthToken?: boolean;
  includeClientData?: boolean;
};

export const defaultHeadersBuilderOptions = {
  includeAuthToken: true,
  includeClientData: true,
};

export function headersBuilder(
  options: HeadersBuilderOptions = defaultHeadersBuilderOptions,
): CustomHeaders {
  const headers: CustomHeaders = {};

  if (
    options.includeAuthToken ??
    defaultHeadersBuilderOptions.includeAuthToken
  ) {
    headers.Authorization = getToken();
  }

  if (
    options.includeClientData ??
    defaultHeadersBuilderOptions.includeClientData
  ) {
    headers['sibly-client'] = getSiblyClientHeader();
  }

  return headers;
}
