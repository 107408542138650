import cn from 'classnames';
import styles from './stepper.module.scss';

type StepProps = {
  active: boolean;
  id: string;
};

function Step({ active, id }: StepProps) {
  return <div className={cn(styles.step, active && styles.active)} id={id} />;
}

type StepperProps = {
  stepperId: string;
  currentStepIndex?: number;
  stepsIds: string[];
  className?: string;
};

export function Stepper({
  stepperId,
  currentStepIndex,
  stepsIds,
  className,
}: StepperProps) {
  return (
    <div className={cn(styles.stepperContainer, className)}>
      {stepsIds.map((stepId, stepIndex) => (
        <Step
          key={`${stepperId}-step-${stepId}`}
          active={stepIndex === currentStepIndex}
          id={stepId}
        />
      ))}
    </div>
  );
}

Stepper.defaultProps = {
  currentStepIndex: 0,
  className: undefined,
};
