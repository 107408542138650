import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';

export function setSentryCoachInScope(user: Sentry.User) {
  Sentry.setUser(user);
}

export function setSentryTag(name: string, value: any) {
  Sentry.setTag(name, value);
}

export function setSentryBreadcrumb(
  message: string,
  category = 'app',
  level: SeverityLevel = 'info',
) {
  Sentry.addBreadcrumb({ category, message, level });
}

export function captureSentryException(error: any) {
  Sentry.captureException(error);
}

export function captureSentryMessage(message: string, level: SeverityLevel) {
  Sentry.captureMessage(message, level);
}
