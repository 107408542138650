import { FieldValidity } from '@utils/forms';
import { InputHTMLAttributes, MouseEventHandler } from 'react';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  inputContainerClassName?: string;
  inputContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  id: string;
  label: string | null;
  labelPosition?: 'top' | 'inner';
  caption?: string;
  validity?: FieldValidity;
  className?: string;
  validateOnMount?: boolean;
  validateOnBlur?: boolean;
  inputTestId?: string;
  phoneCode?: string;
  withSearchIcon?: boolean;
  withClearButton?: boolean;
  withCalendarButton?: boolean;
  handleClearInput?: MouseEventHandler;
  handleCalendarClick?: MouseEventHandler;
};

export const defaultProps = {
  inputContainerClassName: undefined,
  inputContainerRef: undefined,
  inputRef: undefined,
  type: 'text',
  labelPosition: 'inner',
  caption: undefined,
  validity: FieldValidity.NOT_VALIDATED,
  className: undefined,
  validateOnMount: false,
  validateOnBlur: false,
  inputTestId: undefined,
  phoneCode: undefined,
  withSearchIcon: false,
  withClearButton: false,
  withCalendarButton: false,
  handleClearInput: undefined,
  handleCalendarClick: undefined,
};
