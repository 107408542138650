import { Footer } from '@components/Footer';
import { Navbar } from '@components/Navbar';
import { Stepper } from '@components/Stepper';
import { OnboardingStep } from '@stores/models/Onboarding';
import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { UNMASK_CLASSNAME } from '@common/FullstoryMask/FullStoryMask';
import styles from './onboarding.module.scss';

type OnboardingLayoutProps = PropsWithChildren<{
  steps: OnboardingStep[];
  selectedStepIndex: number;
}>;

export function OnboardingLayout({
  steps,
  selectedStepIndex,
  children,
}: OnboardingLayoutProps) {
  return (
    <div
      className={cn(styles.onboarding, UNMASK_CLASSNAME)}
      data-testid="onboarding-layout"
    >
      <Navbar />
      <main className={styles.contentContainer}>
        <Stepper
          stepperId="onboarding"
          stepsIds={steps}
          currentStepIndex={selectedStepIndex}
        />
        {children}
      </main>
      <Footer />
    </div>
  );
}
