import { ChangeEventHandler, FormEventHandler, useMemo } from 'react';
import { Toast } from '@components/Toast';
import { FieldValidity } from '@utils/forms';
import { useBreakpoints } from '@utils/hooks/useBreakpoints';
import { SubmitButton } from '@components/Button';
import { MaskedInput } from '@components/MaskedInput';
import {
  ErrorCaption,
  SafetyDataVolatile,
} from '@stores/models/Onboarding/SafetyDataModel';
import { DateInput } from '@components/DateInput';
import { PhoneInput } from '@components/PhoneInput';
import { telNational } from '@utils/inputAutocomplete';
import styles from './safety-data-form.module.scss';

import {
  safetyDataAddressSecondLineInputName,
  safetyDataAdressInputName,
  safetyDataBirthdayInputName,
  safetyDataCityInputName,
  safetyDataLegalNameInputName,
  safetyDataStateInputName,
  safetyDataZipInputName,
} from './utils';

type SafetyDataFormProps = {
  data: SafetyDataVolatile;
  handleFormSubmit: FormEventHandler;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  addressErrorCaption: ErrorCaption;
  birthDateErrorCaption: ErrorCaption;
  phoneNumberErrorCaption: ErrorCaption;
  cityErrorCaption: ErrorCaption;
  nameErrorCaption: ErrorCaption;
  stateErrorCaption: ErrorCaption;
  zipCodeErrorCaption: ErrorCaption;
  safetyFormError: string | undefined;
  isSafetyFormValid: boolean;
  isSavingSafetyForm: boolean;
  includePhoneNumberField: boolean;
};

export function SafetyDataForm({
  data,
  handleFormSubmit,
  handleInputChange,
  addressErrorCaption,
  birthDateErrorCaption,
  phoneNumberErrorCaption,
  cityErrorCaption,
  nameErrorCaption,
  stateErrorCaption,
  zipCodeErrorCaption,
  safetyFormError,
  isSafetyFormValid,
  isSavingSafetyForm,
  includePhoneNumberField,
}: SafetyDataFormProps) {
  const { isMobile } = useBreakpoints();

  const isSubmitDisabled = useMemo(
    () =>
      !isSafetyFormValid ||
      isSavingSafetyForm ||
      (includePhoneNumberField && !!phoneNumberErrorCaption),
    [
      includePhoneNumberField,
      isSafetyFormValid,
      isSavingSafetyForm,
      phoneNumberErrorCaption,
    ],
  );

  return (
    <form
      className={styles.safetyDataForm}
      onSubmit={handleFormSubmit}
      data-testid="safetydata-form"
    >
      <div className={styles.safetyDataFormControls}>
        <MaskedInput
          id="safety-data-legal-name"
          className={styles.safetyDataInput}
          label="Full Name"
          autoComplete="on"
          name={safetyDataLegalNameInputName}
          onChange={handleInputChange}
          validity={nameErrorCaption ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          caption={nameErrorCaption}
          required
          value={data.name}
        />
        <DateInput
          id="safety-data-date-of-birth"
          className={styles.safetyDataInput}
          label="Date of Birth"
          autoComplete="on"
          name={safetyDataBirthdayInputName}
          onChange={handleInputChange}
          validity={birthDateErrorCaption ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          caption={birthDateErrorCaption}
          placeholder="MM/DD/YYYY"
          maxLength={10}
          required
          value={data.birthday}
        />
        {includePhoneNumberField && (
          <PhoneInput
            id="verify-phone-number"
            label="Phone Number"
            countryCode="US"
            autoComplete="on"
            name={telNational}
            showPhoneCode
            onChange={handleInputChange}
            validity={
              phoneNumberErrorCaption ? FieldValidity.NOT_VALID : undefined
            }
            validateOnBlur
            caption={phoneNumberErrorCaption}
          />
        )}
        <MaskedInput
          id="safety-data-street-address"
          className={styles.safetyDataInput}
          label="Street Address"
          autoComplete="on"
          name={safetyDataAdressInputName}
          onChange={handleInputChange}
          validity={addressErrorCaption ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          caption={addressErrorCaption}
          required
          value={data.address1}
        />
        <MaskedInput
          id="safety-data-apartment"
          className={styles.safetyDataInput}
          label="Apartment, Suite, Unit, Floor"
          autoComplete="on"
          placeholder="Optional"
          name={safetyDataAddressSecondLineInputName}
          onChange={handleInputChange}
          value={data.address2}
        />
        <MaskedInput
          id="safety-data-city"
          className={styles.safetyDataInput}
          label="City"
          autoComplete="on"
          name={safetyDataCityInputName}
          onChange={handleInputChange}
          caption={cityErrorCaption}
          validity={cityErrorCaption ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          value={data.city}
        />
        <div className={styles.safetyDataStateZIP}>
          <MaskedInput
            id="safety-data-state"
            label="State"
            className={styles.safetyDataInput}
            autoComplete="on"
            name={safetyDataStateInputName}
            onChange={handleInputChange}
            validity={stateErrorCaption ? FieldValidity.NOT_VALID : undefined}
            validateOnBlur
            caption={stateErrorCaption}
            required
            maxLength={2}
            value={data.state}
          />
          <MaskedInput
            id="safety-data-zip"
            label="ZIP"
            className={styles.safetyDataInput}
            autoComplete="on"
            name={safetyDataZipInputName}
            onChange={handleInputChange}
            validity={zipCodeErrorCaption ? FieldValidity.NOT_VALID : undefined}
            validateOnBlur
            caption={zipCodeErrorCaption}
            required
            maxLength={5}
            value={data.zipCode}
          />
        </div>
      </div>
      {!isMobile && <div className="mt-xxxxs mb-md horizontal-separator" />}
      {safetyFormError && (
        <Toast variant="error" text={safetyFormError} className="mb-md" />
      )}
      <div className={styles.submitButtonContainer}>
        <SubmitButton
          variant="primary"
          type="submit"
          disabled={isSubmitDisabled}
          className={styles.safetyDataFormSubmitButton}
        >
          {isSavingSafetyForm ? 'Saving data...' : 'Next'}
        </SubmitButton>
      </div>
    </form>
  );
}
