import { Input } from '@components/Input';
import { FieldValidity } from '@utils/forms';
import {
  ChangeEventHandler,
  ClipboardEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styles from './code-validation-input.module.scss';

type Props = {
  id: string;
  index: number;
  value: string;
  isActive: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  validity: FieldValidity;
  onPaste: ClipboardEventHandler<HTMLInputElement>;
  setActiveRef: (ref: HTMLInputElement) => unknown;
  onDeleteChar: Function;
  focusActive: Function;
};

export function CodeCell({
  id,
  index,
  value,
  isActive,
  onChange,
  setActiveRef,
  onDeleteChar,
  focusActive,
  validity,
  onPaste,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  // only the active cell should be focusable
  const handleFocusEvent = useCallback(() => {
    if (!isActive) focusActive();
  }, [isActive, focusActive]);

  // change the focused cell when value is deleted
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      // event.which is required in mobile devices
      if (event.code !== 'Backspace' && event.which !== 8) return;
      onDeleteChar();
    },
    [onDeleteChar],
  );

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
      setActiveRef(inputRef.current!);
    }
  }, [isActive, setActiveRef]);

  return (
    <Input
      label={null}
      aria-label={`code validation digit ${index}`}
      id={id}
      inputRef={inputRef}
      value={value}
      maxLength={1}
      type="number"
      onChange={onChange}
      onPaste={onPaste}
      onFocus={handleFocusEvent}
      onKeyDown={handleKeyDown}
      validity={validity}
      inputContainerClassName={styles.codeCell}
    />
  );
}
