import { isAPIError } from '@services/SiblyAPI/isAPIError';
import {
  SafetyDataUpdateBody,
  sendPhoneVerificationCode,
  updateSafetyData,
} from '@services/SiblyAPI/safetyData';
import {
  validatePhoneNumberLength,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { flow, getRoot, types } from 'mobx-state-tree';
import { AxiosError } from 'axios';
import { environmentSelected } from '@environments';
import { trackEvent } from '@integrations/analytics';
import type { RootStoreInstance } from '@stores/Root';
import { getUserId } from '@services/TokenProvider';
import { format } from 'date-fns';
import { captureSentryException } from '@integrations/sentry';
import { SafetyDataVolatile } from './SafetyDataModel';

export type VerifyMethod = 'sms' | 'call';

type VerifyPhoneVolatile = {
  verifyMethodSelected: VerifyMethod;
  verificationCode: string;
  phoneNumber: string;
  timeLeftToEnableResend: number | undefined;

  isSendingCode: boolean;
  codeSentSuccessfully: boolean;
  failedToSendCode: boolean;

  isSavingSafetyData: boolean;
  safetyDataStoredSuccessfully: boolean;
  failedToSubmitSafetyDataMessage: string | undefined;
};

type SendPhoneCodeResult = {
  sent: boolean;
};
const initialVolatileState: VerifyPhoneVolatile = {
  verifyMethodSelected: 'sms',
  phoneNumber: '',
  verificationCode: '',
  timeLeftToEnableResend: undefined,

  isSendingCode: false,
  codeSentSuccessfully: false,
  failedToSendCode: false,

  isSavingSafetyData: false,
  safetyDataStoredSuccessfully: false,
  failedToSubmitSafetyDataMessage: undefined,
};

let resendTimeoutIntervalId: ReturnType<typeof setInterval>;

export const VerifyPhoneModel = types
  .model({})
  .volatile<VerifyPhoneVolatile>(() => initialVolatileState)
  .views((self) => ({
    get phoneNumberInE164format() {
      return parsePhoneNumberFromString(self.phoneNumber, 'US')?.number!;
    },
  }))
  .actions((self) => ({
    setTimeLeftToEnableResend(value: number | undefined) {
      self.timeLeftToEnableResend = value;
    },
  }))
  .actions((self) => ({
    clearResendTimeout() {
      self.setTimeLeftToEnableResend(undefined);
      clearInterval(resendTimeoutIntervalId);
    },
  }))
  .actions((self) => ({
    startResendTimeout() {
      let timeLeft = environmentSelected.resendVerificationCodeTimeout;
      self.clearResendTimeout();
      self.setTimeLeftToEnableResend(timeLeft);

      const { setTimeLeftToEnableResend, clearResendTimeout } = self;

      resendTimeoutIntervalId = setInterval(() => {
        if (timeLeft === 1000) {
          clearResendTimeout();
          return;
        }

        timeLeft -= 1000;
        setTimeLeftToEnableResend(timeLeft);
      }, 1000);
    },
  }))
  .actions((self) => ({
    setVerifyMethodSelected(method: VerifyMethod) {
      self.verifyMethodSelected = method;
    },
    setPhoneNumber(value: string) {
      self.phoneNumber = value;
    },
    setVerificationCode(value: string) {
      self.verificationCode = value;
    },
    sendCode: flow(function* sendCode() {
      try {
        self.isSendingCode = true;
        self.failedToSendCode = false;

        const result: SendPhoneCodeResult = yield sendPhoneVerificationCode({
          channel: self.verifyMethodSelected,
          phoneNumber: self.phoneNumberInE164format,
        });

        if (result.sent) {
          self.codeSentSuccessfully = true;
          trackEvent('Verification Code Received', {
            origin: 'web',
            verificationMethod: self.verifyMethodSelected,
          });
        } else {
          self.failedToSendCode = true;
        }
      } catch (e) {
        self.failedToSendCode = true;
        throw e;
      } finally {
        self.isSendingCode = false;
      }
    }),
  }))
  .actions((self) => ({
    resendCode: flow(function* resendCode() {
      trackEvent('Resend SMS Code Clicked', {
        page: 'Enter Verification Code',
        source: 'Onboarding',
        origin: 'web',
        verificationMethod: self.verifyMethodSelected,
      });
      try {
        yield self.sendCode();
        self.startResendTimeout();
      } catch (e) {
        captureSentryException(e);
      }
    }),
    verifyCode: flow(function* verifyCode() {
      try {
        self.failedToSubmitSafetyDataMessage = undefined;
        self.isSavingSafetyData = true;

        const root = getRoot<RootStoreInstance>(self);

        const {
          signUp: { organization },
        } = root;

        const safetyDataForm = root.onboarding.safetyData
          .safetyDataForm as SafetyDataVolatile;

        const parsedDateOfBirth = root.onboarding.safetyData
          .parsedDateOfBirth as Date;

        const organizationId = organization?.id! as number;
        const userId = getUserId() as number;

        const formatted: SafetyDataUpdateBody = {
          organizationId,
          memberId: userId,
          fullName: safetyDataForm.name,
          address: safetyDataForm.address1,
          address2: safetyDataForm.address2 || undefined,
          phoneNumber: self.phoneNumberInE164format,
          dateOfBirth: format(parsedDateOfBirth, 'yyyy-MM-dd'),
          city: safetyDataForm.city,
          state: safetyDataForm.state,
          zipCode: safetyDataForm.zipCode,
          phoneVerificationCode: self.verificationCode,
        };

        yield updateSafetyData(formatted);

        self.safetyDataStoredSuccessfully = true;
        trackEvent('Phone Verified', {
          page: 'Enter Verification Code',
          source: 'Onboarding',
          origin: 'web',
        });
      } catch (error) {
        console.error(error);
        // not a request error, unexpected error
        if (!isAPIError(error)) {
          self.failedToSubmitSafetyDataMessage =
            'Failed to verify data, try again later.';
          return;
        }

        // request error
        const apiError = error as AxiosError;
        self.failedToSubmitSafetyDataMessage =
          apiError.response?.data?.error?.message;

        // TODO we need error codes on BE, for now we can track this for any error
        trackEvent('Invalid Verification Code Entered', {
          page: 'Enter Verification Code',
          source: 'Onboarding',
          origin: 'web',
        });
      } finally {
        self.isSavingSafetyData = false;
      }
    }),
  }))
  .actions((self) => ({
    sendVerificationCodeWithMethod(method: VerifyMethod) {
      self.verifyMethodSelected = method;
      self.setTimeLeftToEnableResend(undefined);
      self.resendCode();
    },
    reset() {
      Object.assign(self, initialVolatileState);
      self.clearResendTimeout();
    },
  }))
  .views((self) => ({
    get last4Numbers() {
      return self.phoneNumber.slice(-4);
    },
    get isPhoneNumberComplete() {
      const validation = validatePhoneNumberLength(self.phoneNumber, 'US');
      return !validation;
    },
    get phoneNumberErrorCaption() {
      if (!self.phoneNumber) {
        return 'Phone number is required.';
      }
      if (!this.isPhoneNumberComplete) {
        return 'Please provide a valid phone number';
      }
      return undefined;
    },
    get isSendCodeDisabled() {
      return !this.isPhoneNumberComplete || self.isSendingCode;
    },
    get timeLeftToResendFormatted(): string | undefined {
      if (!self.timeLeftToEnableResend) return undefined;

      const minutes = Math.floor(self.timeLeftToEnableResend / 1000 / 60)
        .toString()
        .padStart(2, '0');

      const seconds = Math.floor((self.timeLeftToEnableResend / 1000) % 60)
        .toString()
        .padStart(2, '0');

      return `Resend in ${minutes}:${seconds}`;
    },
    get verifyCodeButtonLabel() {
      if (self.isSavingSafetyData) return 'Verifying...';
      if (self.isSendingCode) return 'Sending Code...';
      if (this.timeLeftToResendFormatted) return this.timeLeftToResendFormatted;
      return 'Resend';
    },
    get changeMethodButtonlabel() {
      if (self.verifyMethodSelected === 'call') {
        return 'Text me instead';
      }
      return 'Call me instead';
    },
    get isResendDisabled() {
      return (
        self.isSavingSafetyData ||
        !!self.timeLeftToEnableResend ||
        self.isSendingCode
      );
    },
  }));
