import { Toast } from '@components/Toast';
import { SubmitButton } from '@components/Button/SubmitButton';
import { FieldValidity } from '@utils/forms';
import { MaskedInput } from '@components/MaskedInput';
import { trackEvent } from '@integrations/analytics';
import { captureSentryException } from '@integrations/sentry';
import { useStore } from '@stores/storeProvider';
import { useBreakpoints } from '@utils/hooks/useBreakpoints';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styles from '../onboarding.module.scss';

type PreferredNameProps = {
  goToNextStep: () => void;
};

function PreferredNameComponent({ goToNextStep }: PreferredNameProps) {
  const [formError, setFormError] = useState<string | null>(null);

  const {
    onboarding: {
      preferredName: {
        preferredName,
        updateDisplayName,
        setPreferredName,
        isPreferredNameValid,
        isSavingPreferredName,
        preferredNameErrorCaption,
      },
    },
  } = useStore();

  const isSubmitDisabled = useMemo(
    () => !isPreferredNameValid || isSavingPreferredName,
    [isPreferredNameValid, isSavingPreferredName],
  );

  const { isMobile } = useBreakpoints();

  useEffect(() => {
    trackEvent('Display Name Page Displayed');
  }, []);

  const handleSubmit: React.FormEventHandler = async (e) => {
    try {
      setFormError(null);
      e.preventDefault();
      await updateDisplayName();
      goToNextStep();
    } catch (error) {
      captureSentryException(error);
      setFormError((error as Error).message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPreferredName(value);
  };

  return (
    <>
      <h1 className="header-lg-4 mt-xxs mb-0">Nice to meet you!</h1>
      <p className="text-lg-2 m-0">
        What name would you like Sibly coaches to call you?
      </p>
      <form onSubmit={handleSubmit}>
        {formError && <Toast text={formError} variant="error" />}
        <MaskedInput
          id="preferred-name"
          label="First Name or Nickname*"
          labelPosition="inner"
          className={styles.nameInput}
          onChange={handleInputChange}
          autoComplete="given-name"
          spellCheck={false}
          validity={!isPreferredNameValid ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          caption={preferredNameErrorCaption}
          value={preferredName}
          required
        />
        {!isMobile && <div className="mt-xl horizontal-separator" />}
        <div className={cn(styles.submitButtonContainer, 'mt-md')}>
          <SubmitButton
            variant="primary"
            type="submit"
            className={styles.submitButton}
            disabled={isSubmitDisabled}
          >
            {isSavingPreferredName ? 'Saving...' : 'Next'}
          </SubmitButton>
        </div>
      </form>
    </>
  );
}

export const PreferredName = observer(PreferredNameComponent);
