import { useEffect, useState } from 'react';

const breakpointLarge = 1280;
const breakpointMedium = 768;

function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);
  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query);
      // Update the state with the current value
      setMatches(mediaQuery.matches);
      // Create an event listener
      const handler = (event: MediaQueryListEvent) => setMatches(event.matches);
      // Attach the event listener to know when the matches value changes
      mediaQuery.addEventListener('change', handler);
      // Remove the event listener on cleanup
      return () => mediaQuery.removeEventListener('change', handler);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // Empty array ensures effect is only run on mount and unmount
  );
  return matches;
}

export function useBreakpoints() {
  const isLgAndAbove = useMediaQuery(`(min-width: ${breakpointLarge}px)`);
  const isMdAndAbove = useMediaQuery(`(min-width: ${breakpointMedium}px)`);

  return {
    isMobile: !isMdAndAbove,
    isTablet: isMdAndAbove && !isLgAndAbove,
    isDesktop: isLgAndAbove,
  };
}
