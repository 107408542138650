import { restCall } from '../restCall';
import { rpcCall } from '../rpcCall';
import { SafetyRPCMethod } from '../types';

export type SafetyDataUpdateBody = {
  organizationId: number;
  memberId: number;
  fullName: string;
  phoneNumber: string;
  dateOfBirth: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  phoneVerificationCode?: string;
};

type SafetyDataGetBody = {
  memberId: number;
};

type SendPhoneVerificationCodeQuery = {
  phoneNumber: string;
  channel: 'sms' | 'call';
};

// creates a standard RPC call that points at the current safety data host
const rpc = rpcCall<SafetyRPCMethod>('safetyData');

const rest = restCall('safetyData');

export const updateSafetyData = async (data: SafetyDataUpdateBody) =>
  rpc({
    method: 'data.update',
    params: data,
  });

export const getSafetyData = async (data: SafetyDataGetBody) =>
  rpc({
    method: 'data.get',
    params: data,
  });

// ------- rest endpoints -----------

export const sendPhoneVerificationCode = async (
  data: SendPhoneVerificationCodeQuery,
) =>
  rest({
    method: 'POST',
    path: 'phoneVerificationCode/send',
    data,
  });
