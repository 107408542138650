import { OnboardingStep } from '@stores/models/Onboarding';
import { useStore } from '@stores/storeProvider';
import { useOnboardingSteps } from '@utils/hooks/useOnboardingSteps';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EnterPhoneVerificationCode } from './EnterPhoneVerificationCode';
import { PreferredName } from './PreferredName';
import { SafetyData } from './SafetyData';
import { VerifyPhone } from './VerifyPhone';

const STEPS_TO_COMPONENTS_MAP: Record<
  OnboardingStep,
  ({ goToNextStep }: { goToNextStep: () => void }) => JSX.Element
> = {
  preferredName: PreferredName,
  safetyData: SafetyData,
  verifyPhone: VerifyPhone,
  enterCode: EnterPhoneVerificationCode,
};

function StepComponent({ goToNextStep }: { goToNextStep: () => void }) {
  const params = useParams<{ stepName: OnboardingStep }>();
  const Component = STEPS_TO_COMPONENTS_MAP[params.stepName as OnboardingStep];

  const onboardingSteps = useOnboardingSteps();

  const {
    onboarding: { setCurrentOnboardingStepIndex },
  } = useStore();

  // set the current onboarding step in the store
  useEffect(() => {
    const stepIndex = onboardingSteps.findIndex(
      (step: string) => step === params.stepName,
    );
    setCurrentOnboardingStepIndex(stepIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.stepName]);

  return <Component goToNextStep={goToNextStep} />;
}

export const Step = observer(StepComponent);
