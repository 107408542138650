import {
  addressLevel1,
  addressLevel2,
  addressLine1,
  addressLine2,
  birthday,
  givenName,
  postalCode,
  telNational,
} from '@utils/inputAutocomplete';

export const safetyDataLegalNameInputName = givenName;
export const safetyDataBirthdayInputName = birthday;
export const safetyDataPhoneInputName = telNational;
export const safetyDataAdressInputName = addressLine1;
export const safetyDataAddressSecondLineInputName = addressLine2;
export const safetyDataStateInputName = addressLevel1;
export const safetyDataCityInputName = addressLevel2;
export const safetyDataZipInputName = postalCode;

export type SafetyDataInputName =
  | typeof safetyDataLegalNameInputName
  | typeof safetyDataAddressSecondLineInputName
  | typeof safetyDataAdressInputName
  | typeof safetyDataBirthdayInputName
  | typeof safetyDataCityInputName
  | typeof safetyDataPhoneInputName
  | typeof safetyDataStateInputName
  | typeof safetyDataZipInputName;
