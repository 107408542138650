import cn from 'classnames';
import { Navbar } from '@components/Navbar';
import { Footer } from '@components/Footer';
import { useEffect } from 'react';
import { Toast } from '@components/Toast';
import { useStore } from '@stores/storeProvider';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { UNMASK_CLASSNAME } from '@common/FullstoryMask/FullStoryMask';
import { getToken } from '@services/TokenProvider';
import { Banner } from '@components/Banner';
import { ReactComponent as Tick } from './assets/Icontick.svg';

import styles from './sign-up.module.scss';
import { SignUpForm } from './SignUpForm';

const tips = [
  {
    content: 'For life’s challenges, as well as its most important milestones',
    key: 'tip-1',
  },
  {
    content: 'Sibly coaches are ready to help right now and around the clock',
    key: 'tip-2',
  },
  {
    content:
      'Guiding you to the right resources and services when you need them',
    key: 'tip-3',
  },
];

function SignUpComponent() {
  const {
    signUp: {
      invalidAccessData,
      formError,
      organization,
      validateAccessCode,
      registerMember,
      isEligibleMember,
    },
    onboarding: { isOnboardingInProgress },
  } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    // onboarding is already started, navigate to current step of onboarding
    if (getToken() && isOnboardingInProgress) {
      navigate('/onboarding', { replace: true });
      return;
    }

    validateAccessCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleFormSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const [email, password] = Array.from(
      (e.target as HTMLFormElement).elements,
    ) as HTMLInputElement[];

    await registerMember({
      email: email.value,
      password: password.value,
    });
    navigate('/onboarding', { replace: true });
  }

  return (
    <div className={cn(styles.signUp, UNMASK_CLASSNAME)}>
      <Navbar />
      <Banner variant="info" show={isEligibleMember} isBelowNavbar>
        <span>Your coverage has been sucessfully confirmed!</span>
      </Banner>
      <div className={styles.contentContainer}>
        {invalidAccessData ? (
          <>
            <h1 data-testid="invalid-access-data-title">
              {invalidAccessData.title}
            </h1>
            {invalidAccessData.subtitle ? (
              <p data-testid="invalid-access-data-subtitle">
                {invalidAccessData.subtitle}
              </p>
            ) : null}
          </>
        ) : (
          <main className={styles.content} data-testid="signup-main-content">
            <section className={styles.informationSection}>
              <h2
                className={cn(styles.informationSectionHeader, 'header-sm-2')}
              >
                Someone to talk to
              </h2>
              <p
                data-testid="signup-main-content-sponsor"
                className={styles.sponsor}
              >
                <span className={styles.sponsoredBy}>Sponsored by </span>
                {organization && organization.logoUrl ? (
                  <img
                    className={styles.orgLogo}
                    src={organization.logoUrl}
                    alt="organization logo"
                  />
                ) : (
                  organization?.name || ''
                )}
              </p>
              <ul className={styles.tipsList}>
                {tips.map((tip) => (
                  <li key={tip.key}>
                    <Tick className={styles.tick} />
                    {tip.content}
                  </li>
                ))}
              </ul>
            </section>

            <section className={styles.formSection}>
              <h3 className={styles.signUpTitle}>Sign Up</h3>
              <p className={styles.signUpContent}>
                Create an account to start your coaching journey with Sibly
              </p>
              {formError && (
                <Toast
                  className={styles.errorToast}
                  variant="error"
                  text={formError}
                />
              )}
              <SignUpForm onSubmit={handleFormSubmit} />
            </section>
          </main>
        )}
      </div>
      <Footer />
    </div>
  );
}

export const SignUp = observer(SignUpComponent);
