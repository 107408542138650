import OneSignal from 'react-onesignal';
// TODO: add Sentry

/**
 * Set external user id in OneSignal
 */
export function setExternalUserId(userId: number): void {
  // setSentryBreadcrumb('setExternalUserId', 'OneSignalService');
  console.log('[OneSignalService] setExternalUserId called');

  OneSignal.setExternalUserId(userId.toString());
}

/**
 * Set email in OneSignal
 */
export function setEmail(email: string): void {
  // setSentryBreadcrumb('setEmail', 'OneSignalService');
  console.log('[OneSignalService] setEmail called');

  // we have to lower case the email, otherwise OneSignal may throw an error
  OneSignal.setEmail(email.toLowerCase());
}
