import { SnapshotIn, types } from 'mobx-state-tree';
import DateTime from '@utils/mst/DateTime';

export const Organization = types.model({
  id: types.identifierNumber,
  name: types.string,
  industry: types.maybeNull(types.string),
  createdAt: DateTime,
  logoUrl: types.maybeNull(types.string),
  onboardingId: types.integer,
});

export type OrganizationIn = SnapshotIn<typeof Organization>;

export const formatOrganization = <T extends OrganizationIn>(
  input: T,
): OrganizationIn => ({
  id: input.id,
  name: input.name,
  industry: input.industry,
  createdAt: input.createdAt,
  logoUrl: input.logoUrl,
  onboardingId: input.onboardingId,
});
