import { SubmitButton } from '@components/Button';
import { FieldValidity } from '@utils/forms';
import { Select } from '@components/Select';
import { useStore } from '@stores/storeProvider';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { Relationship } from '@stores/models/Eligibility/ConfirmEligibilityCoverage';
import { DatePicker } from '@components/Datepicker';
import { MaskedInput } from '@components/MaskedInput';
import { SelectOption } from '@components/Select/SelectDropdown';
import {
  confirmCoverageBirthdayInputName,
  confirmCoverageFirstNameInputName,
  confirmCoverageLastNameInputName,
} from './autocompleteNames';
import styles from './confirm-coverage.module.scss';

const relationshipSelectOptions = [
  {
    value: Relationship.EMPLOYEE,
    label: 'Employee',
    id: 'relationship-employee',
  },
  {
    value: Relationship.SPOUSE,
    label: 'Spouse',
    id: 'relationship-spouse',
  },
  {
    value: Relationship.DEPENDENT,
    label: 'Dependent',
    id: 'relationship-dependent',
  },
];

function ConfirmCoverageFormComponent() {
  const {
    confirmEligibilityCoverage: {
      firstNameInputValue,
      lastNameInputValue,
      birthDateInputValue,
      birthDateErrorCaption,
      formattedDateOfBirth,
      handleDateOfBirthChange,
      setFirstName,
      setLastName,
      firstNameErrorCaption,
      lastNameErrorCaption,
      isFormSubmitEnabled,
      selectRelationship,
      relationshipToOrganization,
      validateMemberEligibility,
      submitButtonLabel,
    },
  } = useStore();

  const handleFirstNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFirstName(e.target.value);
    },
    [setFirstName],
  );

  const handleLastNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLastName(e.target.value);
    },
    [setLastName],
  );

  const handleSelectRelationship = useCallback(
    (selectedOption: SelectOption) => {
      selectRelationship(selectedOption.value as Relationship);
    },
    [selectRelationship],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      validateMemberEligibility();
    },
    [validateMemberEligibility],
  );

  const selectedRelationshipOption = useMemo(
    () =>
      relationshipSelectOptions.find(
        (option) => option.value === relationshipToOrganization,
      ),
    [relationshipToOrganization],
  );

  return (
    <form
      className={styles.confirmCoverageForm}
      onSubmit={handleSubmit}
      data-testid="confirm-coverage-form"
    >
      <div className={styles.nameRow}>
        <MaskedInput
          id="firstName"
          label="First Name"
          autoComplete="on"
          spellCheck={false}
          name={confirmCoverageFirstNameInputName}
          className={styles.nameInput}
          caption={firstNameErrorCaption}
          validity={firstNameErrorCaption ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          onChange={handleFirstNameChange}
          value={firstNameInputValue}
          required
        />
        <MaskedInput
          id="lastName"
          label="Last Name"
          autoComplete="on"
          spellCheck={false}
          name={confirmCoverageLastNameInputName}
          className={styles.nameInput}
          caption={lastNameErrorCaption}
          validity={lastNameErrorCaption ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          onChange={handleLastNameChange}
          value={lastNameInputValue}
          required
        />
      </div>
      <div>
        <DatePicker
          id="safety-data-date-of-birth"
          label="Date of Birth"
          formattedDate={formattedDateOfBirth}
          onChange={handleDateOfBirthChange}
          name={confirmCoverageBirthdayInputName}
          autocomplete
          selected={birthDateInputValue}
          validity={birthDateErrorCaption ? FieldValidity.NOT_VALID : undefined}
          validateOnBlur
          caption={birthDateErrorCaption}
        />
      </div>
      <Select
        id="relationship-to-organization"
        name="Relationship to organization"
        options={relationshipSelectOptions}
        selectedOption={selectedRelationshipOption}
        handleSelectOption={handleSelectRelationship}
      />

      <div className="horizontal-separator" />
      <div className={styles.submitButtonContainer}>
        <SubmitButton
          variant="primary"
          type="submit"
          disabled={!isFormSubmitEnabled}
        >
          {submitButtonLabel}
        </SubmitButton>
      </div>
    </form>
  );
}

export const ConfirmCoverageForm = observer(ConfirmCoverageFormComponent);
