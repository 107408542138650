import { isAValidDate } from '@utils/dates';
import { formatISO } from 'date-fns';
import { types } from 'mobx-state-tree';

const DateTime = types.custom<
  string | null | undefined,
  Date | null | undefined
>({
  name: 'DateTime',
  fromSnapshot: (snapshot) => {
    if (snapshot === undefined) {
      return undefined;
    }

    if (snapshot === null) {
      return null;
    }

    return new Date(snapshot);
  },
  toSnapshot: (maybeDate) => {
    if (maybeDate instanceof Date) {
      return formatISO(maybeDate);
    }
    return maybeDate;
  },
  isTargetType: (maybeMoment) => isAValidDate(maybeMoment),
  getValidationMessage: (snapshot) => {
    if (snapshot === null || snapshot === undefined) {
      return '';
    }

    const date = new Date(snapshot);
    if (!isAValidDate(date)) {
      const message = `"${snapshot}" is not in valid date format.`;

      throw new Error(message);
    }

    return '';
  },
});

export default DateTime;
