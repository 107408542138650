import { types } from 'mobx-state-tree';
import { VerifyPhoneModel } from './VerifyPhone';
import { OnboardingPreferredNameModel } from './PreferredName';
import { OnboardingSafetyDataModel } from './SafetyDataModel';

export type OnboardingStep =
  | 'preferredName'
  | 'safetyData'
  | 'verifyPhone'
  | 'enterCode';

export function getOnboardingSteps({
  isPhoneVerificationEnabled,
}: {
  isPhoneVerificationEnabled: boolean;
}): OnboardingStep[] {
  return isPhoneVerificationEnabled
    ? ['preferredName', 'safetyData', 'verifyPhone', 'enterCode']
    : ['preferredName', 'safetyData'];
}

export const Onboarding = types
  .model({
    currentOnboardingStepIndex: types.maybe(types.integer),
    isOnboardingInProgress: types.maybe(types.boolean),
    safetyData: OnboardingSafetyDataModel,
    preferredName: OnboardingPreferredNameModel,
    verifyPhone: VerifyPhoneModel,
  })
  .actions((self) => ({
    setCurrentOnboardingStepIndex(stepIndex: number) {
      self.currentOnboardingStepIndex = stepIndex;
    },
    setIsOnboardingInProgress(value: boolean) {
      self.isOnboardingInProgress = value;
    },

    resetOnboardingState() {
      self.preferredName.reset();
      self.safetyData.reset();
      self.verifyPhone.reset();
      self.currentOnboardingStepIndex = undefined;
      self.isOnboardingInProgress = undefined;
    },
    restartOnboarding() {
      self.preferredName.reset();
      self.safetyData.reset();
      self.currentOnboardingStepIndex = 0;
    },
  }));
