import { Input } from '@components/Input';
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/storeProvider';
import { isMobile } from 'react-device-detect';

type OrganzationInputProps = { focusOnMount?: boolean };

function OrganizationInputComponent({ focusOnMount }: OrganzationInputProps) {
  const {
    eligibility: {
      organizationInputValue,
      setOrganzationInputValue,
      clearOrganizationInput,
      showClearButton,
      showMobileAutocomplete,
    },
  } = useStore();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnMount) inputRef.current?.focus();
  }, [focusOnMount]);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;

      setOrganzationInputValue(value);
    },
    [setOrganzationInputValue],
  );

  const handleClearInput = useCallback(() => {
    clearOrganizationInput();
  }, [clearOrganizationInput]);

  const handleFocus = useCallback(() => {
    if (isMobile) {
      showMobileAutocomplete();
    }
  }, [showMobileAutocomplete]);

  return (
    <Input
      id="find-organization-input"
      inputTestId="find-organization-input"
      label="Find your organization"
      labelPosition="top"
      placeholder="Search..."
      value={organizationInputValue}
      onChange={handleInputChange}
      onFocus={handleFocus}
      withSearchIcon
      withClearButton={showClearButton}
      handleClearInput={handleClearInput}
      inputRef={inputRef}
    />
  );
}

OrganizationInputComponent.defaultProps = { focusOnMount: false };

export const OrganizationInput = observer(OrganizationInputComponent);
