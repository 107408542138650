import OneSignal from 'react-onesignal';
import { environmentSelected } from '@environments';

export const initOneSignal = (): void => {
  // TODO: disable OneSignal popups during Cypress testing
  console.log('[OneSignal] initOneSignal() called.');
  OneSignal.init(environmentSelected.oneSignal);

  // TODO: add event listeners when we support push notifications to member web app
  // code will need to use react-onesignal `on`, for example:
  // OneSignal.on('subscriptionChange', function(isSubscribed) {
  //   console.log("The user's subscription state is now:", isSubscribed);
  // });

  // below code pasted from mobile app for reference
  // OneSignal.addSubscriptionObserver((event) => {
  //   if (event.to.userId !== event.from.userId) {
  //     // oneSignal player id change
  //     XLogger.logDebug(`[OneSignal Listener] userId changed to ${event.to.userId}`);
  //     Application.onOneSignalPlayerIdChange(event.to.userId);
  //   }
  //   if (event.to.pushToken !== event.from.pushToken) {
  //     XLogger.logDebug(`[OneSignal Listener] push token changed to ${event.to.pushToken}`);
  //     Application.onPushTokenChange(event.to.pushToken);
  //   }
  // });

  // Runs before displaying a notification while the app is in focus.
  // OneSignal.setNotificationWillShowInForegroundHandler((notifReceivedEvent) => {
  //   Application.onPushReceived();
  //   // silence the notification so it's not displayed by passing no arguments to complete()
  //   setTimeout(() => notifReceivedEvent.complete(), 0);
  // });

  // OneSignal.setInAppMessageClickHandler((event) => {
  //   // if user clicks on a button with an ActionName, log that in Mixpanel
  //   if(event.click_name) {
  //     Analytics.track(
  //       'IAM Button Clicked',
  //       {
  //         'Action Name': event.click_name,
  //       },
  //     );
  //   }
  // });
}
