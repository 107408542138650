import cn from 'classnames';
import { useMemo } from 'react';
import styles from './select.module.scss';
import { SelectDropdownOption } from './SelectDropdownOption';

export type SelectOption = {
  id: string;
  label: string;
  value: string;
};

type SelectDropdownProps = {
  id: string;
  isVisible: boolean;
  options: SelectOption[];
  selectedOption?: SelectOption;
  onSelectOption: (selectedOption: SelectOption) => unknown;
};

export function SelectDropdown(props: SelectDropdownProps) {
  const { id, isVisible, selectedOption, onSelectOption, options } = props;

  const selectDropdownClasses = useMemo(
    () => [styles.selectDropdown, isVisible ? styles.visible : styles.hidden],
    [isVisible],
  );

  return (
    <div className={cn(selectDropdownClasses)} id={id}>
      <ul className={styles.selectOptionsContainer}>
        {options.map((option) => (
          <SelectDropdownOption
            key={`${id}-${option.id}`}
            isSelected={selectedOption?.value === option.value}
            onSelect={onSelectOption}
            {...option}
          />
        ))}
      </ul>
    </div>
  );
}

SelectDropdown.defaultProps = {
  selectedOption: undefined,
};
