import { observer } from 'mobx-react-lite';

import { useStore } from '@stores/storeProvider';

import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useOnboardingSteps } from '@utils/hooks/useOnboardingSteps';
import { OnboardingLayout } from './OnboardingLayout';
import { Step } from './steps/Step';

function OnboardingComponent() {
  const {
    onboarding: {
      currentOnboardingStepIndex,
      setCurrentOnboardingStepIndex,
      restartOnboarding,
      preferredName: { getMemberData },
    },
  } = useStore();

  const navigate = useNavigate();

  // if onboarding steps change due to a change of feature flags
  // we restart the onboarding process
  const handleOnboardingStepsChange = useCallback(
    (newSteps) => {
      restartOnboarding();
      navigate(`/onboarding/${newSteps[0]}`);
    },
    [navigate, restartOnboarding],
  );

  const onboardingSteps = useOnboardingSteps({ handleOnboardingStepsChange });

  const currentStepIndex = useMemo(
    () => currentOnboardingStepIndex || 0,
    [currentOnboardingStepIndex],
  );

  const handleGoToNextStep = useCallback(() => {
    const nextStepIndex = currentStepIndex + 1;
    setCurrentOnboardingStepIndex(nextStepIndex);
    navigate(`/onboarding/${onboardingSteps[nextStepIndex]}`);
  }, [
    currentStepIndex,
    navigate,
    onboardingSteps,
    setCurrentOnboardingStepIndex,
  ]);

  // fetch member data to autocomplete onboarding steps
  useEffect(() => {
    getMemberData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingLayout
      steps={onboardingSteps}
      selectedStepIndex={currentStepIndex}
    >
      <Routes>
        <Route
          path=":stepName"
          element={<Step goToNextStep={handleGoToNextStep} />}
        />
        <Route
          index
          element={
            <Navigate to={`/onboarding/${onboardingSteps[currentStepIndex]}`} />
          }
        />
      </Routes>
    </OnboardingLayout>
  );
}

export const Onboarding = observer(OnboardingComponent);
