import { Environment } from './types';

export const env4: Environment = {
  name: 'env4',

  sibly: {
    platform: {
      host: 'https://helios.env4.sibly.cloud/v1',
    },

    safetyData: {
      host: 'https://safetydata.env4.sibly.cloud/v1/',
    },
  },

  sentry: {
    dsn: 'https://4de4b9e2fa0a40c8958b39fe363d85f7@o328515.ingest.sentry.io/6600662',
    project: 'member-webapp',
  },

  segment: {
    key: 'CudGpRuQq7dJEjB9HvtohHUJbsFoarVQ',
    track: true,
  },

  // staging key
  split: {
    key: 'k24f71keue19amad2nbqmqrmt4uupqd0l3g5',
  },

  fullStory: {
    orgId: '182P5Q',
    active: false,
  },

  oneSignal: {
    appId: '3b01dbba-2f28-426f-a4da-bde35e3abb73',
  },

  onboarding: {
    appDownloadLink: 'https://sibly.app.link/test_web_rivian',
    onboardingQRName: 'test',
  },

  sessionTimeout: 4 * 60 * 60 * 1000, // 4 hours
  resendVerificationCodeTimeout: 60 * 1000, // 1 minute
};
