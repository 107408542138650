import {
  addTokenStorageEvent,
  checkIfTokenExpired,
  getToken,
} from '@services/TokenProvider';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PrivateRouteComponent({ children }: { children: ReactElement }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (getToken()) return;

    navigate(`/eligibility`, { replace: true });
  }, [location.pathname, navigate]);

  addTokenStorageEvent();
  checkIfTokenExpired();

  return children;
}

export const PrivateRoute = observer(PrivateRouteComponent);
