import {
  safetyDataAddressSecondLineInputName,
  safetyDataAdressInputName,
  safetyDataBirthdayInputName,
  safetyDataCityInputName,
  SafetyDataInputName,
  safetyDataLegalNameInputName,
  safetyDataPhoneInputName,
  safetyDataStateInputName,
  safetyDataZipInputName,
} from '@common/SafetyDataForm/utils';
import {
  SafetyDataUpdateBody,
  updateSafetyData,
} from '@services/SiblyAPI/safetyData';
import { getUserId } from '@services/TokenProvider';
import type { RootStoreInstance } from '@stores/Root';

import { isAValidDate, dateOfBirthRegex } from '@utils/dates';
import { isValidUSState } from '@utils/USStates';
import {
  differenceInYears,
  format,
  getYear,
  parse as parseDate,
} from 'date-fns';
import {
  validatePhoneNumberLength,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { flow, getRoot, types } from 'mobx-state-tree';

const zipCodeRegex = /^\d{5}$/;

export type ErrorCaption = string | undefined;

export type SafetyDataVolatile = {
  name: string;
  birthday: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
};

type VolatileState = {
  safetyDataForm: SafetyDataVolatile;
  isSavingContactDetails: boolean;
  safetyFormError?: string;
};

// map input names(required for browser autocomplete) to
// mobx volatile state field names
const INPUT_NAME_STATE_VALUE_MAP: Record<
  SafetyDataInputName,
  keyof VolatileState['safetyDataForm']
> = {
  [safetyDataLegalNameInputName]: 'name',
  [safetyDataBirthdayInputName]: 'birthday',
  [safetyDataPhoneInputName]: 'phoneNumber',
  [safetyDataAdressInputName]: 'address1',
  [safetyDataAddressSecondLineInputName]: 'address2',
  [safetyDataCityInputName]: 'city',
  [safetyDataStateInputName]: 'state',
  [safetyDataZipInputName]: 'zipCode',
};

const initialVolatile: VolatileState = {
  safetyDataForm: {
    name: '',
    birthday: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  },
  isSavingContactDetails: false,
};

export const OnboardingSafetyDataModel = types
  .model()
  .volatile<VolatileState>(() => initialVolatile)
  .views((self) => ({
    get parsedDateOfBirth() {
      return parseDate(self.safetyDataForm.birthday, 'MM/dd/yyyy', new Date());
    },
    get phoneNumberInE164format() {
      return parsePhoneNumberFromString(self.safetyDataForm.phoneNumber, 'US')
        ?.number!;
    },
  }))
  .actions((self) => ({
    setInputValue(inputName: SafetyDataInputName, inputValue: string) {
      self.safetyDataForm = {
        ...self.safetyDataForm,
        [INPUT_NAME_STATE_VALUE_MAP[inputName]]: inputValue,
      };
    },
    submitContactDetails: flow(function* submitContactDetails() {
      try {
        self.safetyFormError = undefined;
        self.isSavingContactDetails = true;

        const root = getRoot<RootStoreInstance>(self);

        const {
          signUp: { organization },
        } = root;

        const organizationId = organization?.id! as number;
        const userId = getUserId() as number;

        const formatted: SafetyDataUpdateBody = {
          organizationId,
          memberId: userId,
          fullName: self.safetyDataForm.name,
          address: self.safetyDataForm.address1,
          address2: self.safetyDataForm.address2 || undefined,
          phoneNumber: self.phoneNumberInE164format,
          dateOfBirth: format(self.parsedDateOfBirth, 'yyyy-MM-dd'),
          city: self.safetyDataForm.city,
          state: self.safetyDataForm.state,
          zipCode: self.safetyDataForm.zipCode,
        };

        yield updateSafetyData(formatted);
      } catch (error) {
        self.safetyFormError = 'Something went wrong';
        throw new Error('Something went wrong');
      } finally {
        self.isSavingContactDetails = false;
      }
    }),
    reset() {
      Object.assign(self, initialVolatile);
    },
  }))
  .views((self) => ({
    get is18OrOlder() {
      return (
        self.safetyDataForm.birthday.length > 0 &&
        differenceInYears(new Date(), self.parsedDateOfBirth) >= 18
      );
    },

    get isBirthDateAboveYear1900() {
      return (
        self.safetyDataForm.birthday.length > 0 &&
        getYear(self.parsedDateOfBirth) >= 1900
      );
    },

    get isPhoneNumberComplete() {
      const validation = validatePhoneNumberLength(
        self.safetyDataForm.phoneNumber,
        'US',
      );
      return !validation;
    },
    get phoneNumberErrorCaption() {
      if (!self.safetyDataForm.phoneNumber) {
        return 'Phone number is required.';
      }
      if (!this.isPhoneNumberComplete) {
        return 'Please provide a valid phone number';
      }
      return undefined;
    },

    get isValidDateOfBirth() {
      return (
        dateOfBirthRegex.test(self.safetyDataForm.birthday) &&
        isAValidDate(self.parsedDateOfBirth)
      );
    },

    get isValidZipCode() {
      return zipCodeRegex.test(self.safetyDataForm.zipCode);
    },

    get nameErrorCaption(): ErrorCaption {
      const trimmedName = self.safetyDataForm.name.trim();

      return trimmedName.length === 0 ? 'Legal Name is Required.' : undefined;
    },

    get birthDateErrorCaption(): ErrorCaption {
      if (!self.safetyDataForm.birthday) {
        return 'Birthday is required.';
      }
      if (!this.isValidDateOfBirth) {
        return 'You must use MM/DD/YYYY format.';
      }
      if (!this.is18OrOlder) {
        return 'You must be at least 18 years old to sign up for Sibly';
      }
      if (!this.isBirthDateAboveYear1900) {
        return 'Min year is 1900';
      }
      return undefined;
    },

    get addressErrorCaption(): ErrorCaption {
      if (self.safetyDataForm.address1.length === 0) {
        return 'Address is required.';
      }
      if (self.safetyDataForm.address1.length > 100) {
        return 'The maximum length of this field is 100 characters.';
      }
      return undefined;
    },

    get cityErrorCaption(): ErrorCaption {
      if (self.safetyDataForm.city.length === 0) {
        return 'City is required.';
      }
      if (self.safetyDataForm.city.length > 100) {
        return 'The maximum length of this field is 100 characters.';
      }
      return undefined;
    },

    get stateErrorCaption(): ErrorCaption {
      if (!self.safetyDataForm.state) {
        return 'State is required.';
      }
      if (self.safetyDataForm.state.length < 2) {
        return 'Please use abbreviation (like CA)';
      }
      if (!isValidUSState(self.safetyDataForm.state)) {
        return `${self.safetyDataForm.state} is not a US state or territory`;
      }
      return undefined;
    },

    get zipCodeErrorCaption(): ErrorCaption {
      if (self.safetyDataForm.zipCode.length === 0) {
        return 'Zip code is required.';
      }
      if (!this.isValidZipCode) {
        return 'Please enter a valid zip code.';
      }
      return undefined;
    },

    get isSafetyFormValid(): boolean {
      return (
        !this.nameErrorCaption &&
        !this.birthDateErrorCaption &&
        !this.addressErrorCaption &&
        !this.cityErrorCaption &&
        !this.stateErrorCaption &&
        !this.zipCodeErrorCaption
      );
    },
  }));
