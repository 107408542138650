import { ReactComponent as SearchIcon } from '@assets/search.svg';
import styles from '../input.module.scss';

type Props = {
  withSearchIcon?: boolean;
};

export function LeftIconContainer({ withSearchIcon }: Props) {
  return withSearchIcon ? <SearchIcon className={styles.searchIcon} /> : null;
}

LeftIconContainer.defaultProps = {
  withSearchIcon: false,
};
