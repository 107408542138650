import { Environment } from './types';

export const prod: Environment = {
  name: 'prod',

  // TODO: configure all prod keys
  sibly: {
    platform: {
      host: 'https://api.mobile.sibly.com/v1/',
    },

    safetyData: {
      host: 'https://api.safetydata.sibly.com/v1/',
    },
  },

  sentry: {
    dsn: 'https://4de4b9e2fa0a40c8958b39fe363d85f7@o328515.ingest.sentry.io/6600662',
    project: 'member-webapp',
  },

  segment: {
    key: 'bCk1jynQ58iI3QR7ewZCWV5NhpKygFc7', // prod
    track: true,
  },

  split: {
    key: 'qs4r0jahpphqhv8ro0rdkfghhhpu135a06a8',
  },

  fullStory: {
    orgId: '182P5Q',
    active: true,
  },

  oneSignal: {
    appId: '01cee702-01e6-4e1c-ba6e-e92bb0ea6830',
  },

  onboarding: {
    appDownloadLink: 'https://sibly.app.link/web_signup_rivian',
    onboardingQRName: 'production',
  },

  sessionTimeout: 4 * 60 * 60 * 1000, // 4 hours
  resendVerificationCodeTimeout: 60 * 1000, // 1 minute
};
