import cn from 'classnames';
import { Footer } from '@components/Footer';
import { ReactComponent as SiblyLogo } from '@assets/sibly-logo.svg';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/storeProvider';
import { trackEvent } from '@integrations/analytics';
import { UNMASK_CLASSNAME } from '@common/FullstoryMask/FullStoryMask';
import { ELIGIBILITY_GET_HELP } from '@utils/constants';
import styles from './eligibility-home.module.scss';
import { ORSeparator } from './ORSeparator';
import { AccessCodeSection } from './AccessCodeSection';
import { HelpSection } from '../HelpSection';
import { OrganizationSection } from './OrganizationSection';
import { MobileOrganzationInput } from './MobileOrganzationInput';

function EligibilityHomeComponent() {
  const {
    eligibility: { fetchAccessCodes, isMobileAutocompleteVisible },
  } = useStore();

  useEffect(() => {
    fetchAccessCodes();
    trackEvent('Enter Org Page Displayed', {
      page: 'FindOrg/AccessCode',
      source: 'Onboarding',
      origin: 'web',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobileAutocompleteVisible ? (
    <MobileOrganzationInput />
  ) : (
    <div
      className={cn(styles.eligibility, UNMASK_CLASSNAME)}
      data-testid="eligibility-desktop-container"
    >
      <div className={styles.contentContainer}>
        <SiblyLogo className={styles.logo} />
        <h1 className={styles.header}>Someone to Talk To</h1>
        <h3 className={styles.subheader}>
          Sibly offers members 24/7 high quality mental health support through
          sponsorship with an employer or organization. Get started by finding
          your organization or entering an access code.
        </h3>
        <div className={styles.organizationOrAccessCodeContainer}>
          <OrganizationSection />
          <ORSeparator />
          <AccessCodeSection />
        </div>
        <div className={styles.helpSectionWrapper}>
          <HelpSection link={ELIGIBILITY_GET_HELP} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export const EligibilityHome = observer(EligibilityHomeComponent);
