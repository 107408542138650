import { InputProps } from '@components/Input/types';
import { MaskedInput } from '@components/MaskedInput';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

import { ChangeEvent, KeyboardEventHandler, useRef } from 'react';
import { formatNumber } from './formatNumber';

type Props = {
  countryCode?: CountryCode;
  showPhoneCode?: boolean;
} & InputProps;

export function PhoneInput(props: Props) {
  const { countryCode, showPhoneCode, onChange, ...inputProps } = props;
  const stoppedPropagation = useRef(false);

  const handlePhoneInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // dont format if it was prevented by onKeyDown handler
    if (stoppedPropagation.current) {
      stoppedPropagation.current = false;
    } else {
      // apply phone formatting to input
      // TODO use formatIncompletePhoneNumber if we need to implement formatting for other countries
      event.target.value = formatNumber(event.target.value);
    }

    if (onChange) {
      onChange(event);
    }
  };

  // prevent adding mask if backspace is pressed
  const handlePhoneInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.code !== 'Backspace') return;
    stoppedPropagation.current = true;
  };
  return (
    <MaskedInput
      {...inputProps}
      onChange={handlePhoneInputChange}
      onKeyDown={handlePhoneInputKeyDown}
      // only for US numbers
      maxLength={14}
      phoneCode={
        props.showPhoneCode
          ? `+${getCountryCallingCode(props.countryCode!)}`
          : undefined
      }
    />
  );
}

PhoneInput.defaultProps = {
  countryCode: 'US',
  showPhoneCode: false,
};
