import { environmentSelected } from '@environments';

const trackEventsQueue: [eventName: string, data: any][] = [];
let identifiedUser: null | [userId: number, accessCode: string] = null;
function getAnalyticsGlobal() {
  return window.analytics as SegmentAnalytics.AnalyticsJS | undefined;
}

export function trackEvent(eventName: string, data: any = {}) {
  const analytics = getAnalyticsGlobal();
  if (environmentSelected.segment.track) {
    // will be tracked once analytics is defined in the DOM
    if (!analytics) {
      trackEventsQueue.push([eventName, data]);
      return;
    }
    analytics.track(eventName, data);
  }
}

export function updateIdentifiedUser(userId: number, accessCode: string) {
  const analytics = getAnalyticsGlobal();
  if (environmentSelected.segment.track) {
    // will be tracked once analytics is defined in the DOM
    if (!analytics) {
      identifiedUser = [userId, accessCode];
      return;
    }
    analytics.identify(userId.toString(), { accessCode });
  }
}

// workaround to track events before the analytics script finishes loading
// instead we push events to this queue and track them once analytics-loaded event is triggered
// in analytics script
export function addAnalyticsLoadEventListener() {
  window.addEventListener('analytics-loaded', () => {
    if (identifiedUser) {
      updateIdentifiedUser(...identifiedUser);
    }
    // track events in queue
    trackEventsQueue.forEach((event) => trackEvent(...event));
  });
}
