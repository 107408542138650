import { trackEvent } from '@integrations/analytics';
import { getEligibilityAccessCodes } from '@services/SiblyAPI/platform';
import { getVerifiedAccessCode } from '@stores/utils/getVerifiedAccessCode';
import { orgFamilyNames } from '@utils/orgFamilyNames';
import { flow, Instance, SnapshotIn, types } from 'mobx-state-tree';
import { AccessCodeModel, AccessCodeModelIn } from './AccessCodeModel';

const memoizedOptions: Record<string, Instance<AccessCodeModelIn>[]> = {};

type FetchAccessCodesPayload = {
  accessCodes: SnapshotIn<AccessCodeModelIn>[];
};

type VolatileState = {
  organizationInputValue: string;
  accessCodeInputValue: string;
  selectedAccessCode: string;
  showFakeOrgMessage: boolean;
  showFailedToLoadMessage: boolean;
  showInvalidAccessCodeError: boolean;
  isMobileAutocompleteVisible: boolean;
};

const initialVolatileState: VolatileState = {
  organizationInputValue: '',
  accessCodeInputValue: '',
  selectedAccessCode: '',
  showFailedToLoadMessage: false,
  showFakeOrgMessage: false,
  showInvalidAccessCodeError: false,
  isMobileAutocompleteVisible: false,
};

export const EligibilityModel = types
  .model({
    accessCodes: types.map(AccessCodeModel),
    selectedEligibilityAccessCode: types.maybe(types.string),
  })
  .volatile<VolatileState>(() => initialVolatileState)
  .actions((self) => ({
    fetchAccessCodes: flow(function* fetchAccessCodes() {
      try {
        const { accessCodes }: FetchAccessCodesPayload =
          yield getEligibilityAccessCodes();

        // store accessCodes data as an object with access code as index
        const accessCodesMap = accessCodes.reduce((acum, code) => {
          acum[code.accessCode] = code;
          return acum;
        }, {} as Record<string, AccessCodeModelIn>);

        self.accessCodes.merge(accessCodesMap);
      } catch (error) {
        self.showFailedToLoadMessage = true;
      }
    }),

    validateAccessCodeEntered: flow(function* validateAccessCodeEntered() {
      try {
        yield getVerifiedAccessCode(self.accessCodeInputValue);
        return true;
      } catch (e) {
        self.showInvalidAccessCodeError = true;
        return false;
      }
    }),

    setOrganzationInputValue(value: string) {
      self.showFakeOrgMessage = false;
      self.organizationInputValue = value;

      // cleanup any previously selected organization
      if (self.selectedAccessCode) self.selectedAccessCode = '';
    },
    setAccessCodeInputValue(value: string) {
      self.accessCodeInputValue = value;
    },
    setSelectedAccessCode(selectedAccessCode: string, organization: string) {
      self.selectedAccessCode = selectedAccessCode;
      self.organizationInputValue = organization;

      this.hideMobileAutocomplete();
    },

    clearOrganizationInput() {
      self.showFakeOrgMessage = false;
      this.hideMobileAutocomplete();
      self.organizationInputValue = '';
      self.selectedAccessCode = '';
    },

    validateOrganizationAccessCode(): boolean {
      const selectedAccessCodeData = self.accessCodes.get(
        self.selectedAccessCode,
      );
      if (!selectedAccessCodeData || !selectedAccessCodeData.isSiblyClient) {
        self.showFakeOrgMessage = true;
        return false;
      }
      return true;
    },

    setSelectedEligibilityAccessCode(code: string) {
      self.selectedEligibilityAccessCode = code;
    },

    showMobileAutocomplete() {
      self.isMobileAutocompleteVisible = true;
    },

    hideMobileAutocomplete() {
      self.isMobileAutocompleteVisible = false;
    },

    trackOrgEntered() {
      const selectedAccessCodeData = self.accessCodes.get(
        self.selectedAccessCode,
      );

      trackEvent('Org Entered', {
        org: selectedAccessCodeData?.organizationName,
        'current client': selectedAccessCodeData?.isSiblyClient,
        page: 'FindOrg/AccessCode',
        source: 'Onboarding',
        origin: 'web',
      });
    },

    trackAccessCodeEntered() {
      trackEvent('Access Code Entered', {
        accessCode: self.accessCodeInputValue,
        page: 'FindOrg/AccessCode',
        source: 'Onboarding',
        origin: 'web',
      });
    },

    resetEligibilityState() {
      self.selectedEligibilityAccessCode = undefined;
      Object.assign(self, initialVolatileState);
    },
  }))
  .views((self) => ({
    get autocompleteValues() {
      if (self.organizationInputValue === '' || !self.accessCodes.size)
        return [];

      // lowercase input to keep just one entry in memoizedOptions
      const input = self.organizationInputValue.trim().toLowerCase();

      // returned memoized results for input if it exists
      if (memoizedOptions[input]) {
        return memoizedOptions[input];
      }

      // match access codes where the org name starts with input value and ignore case
      // also match with organization if input matches the org family names
      const regExp = new RegExp(`^${input}`, 'i');

      const matches = Array.from(self.accessCodes.values()).reduce(
        (acum, code) => {
          if (
            regExp.test(code.organizationName) ||
            (!!orgFamilyNames[code.organizationName] &&
              orgFamilyNames[code.organizationName].test(input))
          ) {
            return [...acum, code];
          }
          return acum;
        },
        [] as Instance<AccessCodeModelIn>[],
      );

      const sortedMatches = matches.sort((matchA, matchB) => {
        if (matchA.isSiblyClient && !matchB.isSiblyClient) {
          return -1;
        }
        if (matchB.isSiblyClient && !matchA.isSiblyClient) {
          return 1;
        }
        return 0;
      });

      memoizedOptions[input] = sortedMatches;

      return sortedMatches;
    },
    get showAutocomplete() {
      return !!self.organizationInputValue && !self.selectedAccessCode;
    },

    get showClearButton() {
      if (self.isMobileAutocompleteVisible) return false;

      return Boolean(self.selectedAccessCode || self.organizationInputValue);
    },
  }));
