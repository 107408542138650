import { restCall } from '../restCall';
import { rpcCall } from '../rpcCall';
import { PlatformRPCMethod } from '../types';

// creates a standard RPC call that points at the current platform host
const rpcPlatform = rpcCall<PlatformRPCMethod>('platform');

const restPlatform = restCall('platform');

export const accessCodeVerifyRPC = async (code: string) =>
  rpcPlatform({
    method: 'accessCode.verify',
    params: { code },
    headersOptions: {
      includeAuthToken: false,
    },
  });

export type RegisterNewMemberParams = {
  email: string;
  password: string;
  accessCode: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  relationshipType?: 'employee' | 'spouse' | 'dependent';
  locale?: string;
};

export const registerNewMember = async (params: RegisterNewMemberParams) =>
  rpcPlatform({
    method: 'member.register',
    params: {
      ...params,
      registrationClient: 'browser',
    },
    headersOptions: {
      includeAuthToken: false,
    },
  });

export const updateMember = async (updatedFields: Record<string, any>) =>
  rpcPlatform({
    method: 'member.update',
    params: updatedFields,
  });

export const getMe = async () =>
  rpcPlatform({
    method: 'member.me',
  });

// -------------- rest endpoints ----------------------

export const getEligibilityAccessCodes = async () =>
  restPlatform({
    method: 'GET',
    path: 'accessCodes/eligibility',
    headersOptions: {
      includeAuthToken: false,
    },
  });

export type MemberCheckEligibilityParams = {
  firstName: string;
  lastName: string;
  dob: string;
  relationshipType: string;
  organizationId: number;
};

export const memberCheckEligibility = async (
  params: MemberCheckEligibilityParams,
) =>
  restPlatform({
    method: 'GET',
    path: 'member/checkEligibility',
    params,
    headersOptions: {
      includeAuthToken: false,
    },
  });
