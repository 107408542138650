import { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from '../input.module.scss';
import { InputProps } from '../types';

export function InnerLabelContainer(
  props: PropsWithChildren<
    Pick<InputProps, 'labelPosition' | 'id' | 'label'> & { inputValue: string }
  >,
) {
  const useInnerLabel = props.label && props.labelPosition === 'inner';

  const containerClasses = [
    styles.innerLabelContainer,
    useInnerLabel && styles.hasInnerLabel,
    props.inputValue.length && styles.hasContent,
  ].filter(Boolean);

  const InnerLabel = useInnerLabel ? (
    <label htmlFor={props.id} className={styles.innerLabel}>
      {props.label}
    </label>
  ) : null;

  return InnerLabel ? (
    <div className={cn(containerClasses)}>
      {InnerLabel}
      {props.children}
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{props.children}</>
  );
}
