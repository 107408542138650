import React, { Suspense } from 'react';
import { environmentSelected } from '@environments';

const QR = React.lazy(() => import('./QRCode'));

type OnboardingQRCodeProps = {
  className?: string;
};

export function OnboardingQRCode({ className }: OnboardingQRCodeProps) {
  return (
    // Lazy loading of QR codes, so that it only fetches them when they are needed,
    // they are quite large svgs
    // Might need to look into alternatives if we add more big assets to the app

    // LoadingQRCode code is just the svg without the QR code.
    <Suspense fallback={<span>Loading QR code...</span>}>
      <QR
        qrCodeName={environmentSelected.onboarding.onboardingQRName}
        className={className}
      />
    </Suspense>
  );
}

OnboardingQRCode.defaultProps = {
  className: undefined,
};
