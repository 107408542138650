import { types } from 'mobx-state-tree';
import { Relationship } from '../Eligibility/ConfirmEligibilityCoverage';

export const EligibilityModel = types.model({
  eligibilityAccessCode: types.string,
  firstName: types.string,
  lastName: types.string,
  dob: types.string,
  relationshipType: types.enumeration(Object.values(Relationship)),
});
