import { FieldValidity } from '@utils/forms';
import {
  ChangeEventHandler,
  ClipboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './code-validation-input.module.scss';
import { CodeCell } from './CodeCell';

type Props = {
  codeLength: number;
  errorCaption?: string;
  handleCodeChange?: (code: string) => unknown;
  handleCodeIsComplete?: (code: string) => unknown;
};

const EMPTY_CELL_VALUE = '*';

export function CodeValidationInput({
  codeLength,
  handleCodeChange,
  handleCodeIsComplete,
  errorCaption,
}: Props) {
  const [code, setCode] = useState<string>('');

  // activeCellRef is the reference to the input cell that should receive focus
  const activeCellRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (handleCodeChange) {
      handleCodeChange(code);
    }
    if (handleCodeIsComplete && code.length === codeLength) {
      handleCodeIsComplete(code);
    }
  }, [handleCodeChange, code, handleCodeIsComplete, codeLength]);

  // cell values will be padded with '*' when there is no value in the cell
  // ex:
  // for codLength = 4,
  // if code is '1'
  // cellValues will be '1***'
  // if code is '12'
  // cellValues will be '12**'
  const cellValues: Array<string> = useMemo(
    () => code.padEnd(codeLength, EMPTY_CELL_VALUE).split(''),
    [code, codeLength],
  );

  // activeCellIndex is first one found with '*' in its value or last one
  const activeCellIndex = useMemo(() => {
    const active = cellValues.findIndex((cell) => cell === EMPTY_CELL_VALUE);
    return active >= 0 ? active : codeLength - 1;
  }, [cellValues, codeLength]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;

      // ignores extra digits on the last cell
      if (value.length > 1) return;

      setCode((val) => val + value);
    },
    [],
  );

  const handlePasteEvent: ClipboardEventHandler = useCallback(
    (event) => {
      const text = event.clipboardData.getData('text');

      // ignore non number content
      if (Number.isNaN(Number(text)) || text.length > codeLength) return;

      setCode(text);
    },
    [codeLength],
  );

  const setActiveRef = useCallback((ref: HTMLInputElement) => {
    activeCellRef.current = ref;
  }, []);

  const handleDeleteChar = useCallback(() => {
    setCode((val) => val.slice(0, val.length - 1));
  }, []);

  const focusActiveCell = useCallback(() => {
    activeCellRef.current?.focus();
  }, []);

  const keys: string[] = useMemo(
    () => new Array(codeLength).fill(null).map(() => uuidv4()),
    [codeLength],
  );

  const renderCodeCells = useCallback(
    () =>
      new Array(codeLength)
        .fill(null)
        .map((__, index) => (
          <CodeCell
            key={`"code-cell-${keys[index]}"`}
            id={`"code-cell-${index}"`}
            index={index}
            value={
              cellValues[index] !== EMPTY_CELL_VALUE ? cellValues[index] : ''
            }
            onDeleteChar={handleDeleteChar}
            isActive={activeCellIndex === index}
            onChange={handleChange}
            onPaste={handlePasteEvent}
            setActiveRef={setActiveRef}
            focusActive={focusActiveCell}
            validity={
              errorCaption
                ? FieldValidity.NOT_VALID
                : FieldValidity.NOT_VALIDATED
            }
          />
        )),
    [
      activeCellIndex,
      cellValues,
      codeLength,
      focusActiveCell,
      handleChange,
      handleDeleteChar,
      handlePasteEvent,
      keys,
      setActiveRef,
      errorCaption,
    ],
  );

  const renderErrorCaption = useCallback(
    () =>
      errorCaption ? (
        <span className={styles.errorCaption}>{errorCaption}</span>
      ) : null,
    [errorCaption],
  );

  return (
    <div className={styles.codeValidationInputContainer}>
      <div className={styles.codeValidationInput}>{renderCodeCells()}</div>
      {renderErrorCaption()}
    </div>
  );
}

CodeValidationInput.defaultProps = {
  errorCaption: undefined,
  handleCodeChange: undefined,
  handleCodeIsComplete: undefined,
};
