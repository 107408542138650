import cn from 'classnames';
import { UNMASK_CLASSNAME } from '@common/FullstoryMask/FullStoryMask';
import { Footer } from '@components/Footer';
import { Navbar } from '@components/Navbar';
import { useStore } from '@stores/storeProvider';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Banner } from '@components/Banner';
import { browserName } from 'react-device-detect';
import { trackEvent } from '@integrations/analytics';
import { ELIGIBILITY_GET_HELP } from '@utils/constants';
import { HelpSection } from '../HelpSection';
import { ConfirmCoverageForm } from './ConfirmCoverageForm';
import styles from './eligibility-confirm-coverage.module.scss';

function EligibilityConfirmCoverageComponent() {
  const {
    confirmEligibilityCoverage: {
      validateAccessCode,
      invalidAccessData,
      orgLogo,
      orgName,
      memberNotEligibleError,
      eligibilityApiError,
      isEligible,
      accessCode,
    },
  } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    validateAccessCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEligible && accessCode) {
      navigate(`/`);
    }
  }, [isEligible, accessCode, navigate]);

  useEffect(() => {
    if (memberNotEligibleError) {
      trackEvent('Error modal displayed', {
        error: 'status not eligible',
        source: 'web',
        browser: browserName,
      });
    }

    if (eligibilityApiError) {
      trackEvent('Error modal displayed', {
        error: 'validation failed',
        source: 'web',
        browser: browserName,
      });
    }
  }, [memberNotEligibleError, eligibilityApiError]);

  return (
    <div
      className={cn(styles.confirmCoverage, UNMASK_CLASSNAME)}
      data-testid="eligibility-confirm-coverage"
    >
      <Navbar />
      <main className={styles.contentContainer}>
        {invalidAccessData ? (
          <div className={styles.invalidAccessDataContainer}>
            <h1 data-testid="invalid-access-data-title">
              {invalidAccessData.title}
            </h1>
            <p data-testid="invalid-access-data-subtitle">
              {invalidAccessData.subtitle}
            </p>
          </div>
        ) : (
          <>
            <section className={styles.sponsor}>
              Sponsored by{' '}
              {orgLogo ? (
                <img src={orgLogo} aria-label="sponsor logo" />
              ) : (
                orgName
              )}
            </section>
            <h3 className={styles.title}>Confirm your coverage</h3>
            <h5 className={styles.subtitle}>
              Your full name and date of birth should match what’s on file with
              your covering organization. If no match is found try using an{' '}
              <Link to="/eligibility/enter-access-code">access code.</Link>
            </h5>
            <ConfirmCoverageForm />
          </>
        )}
        <HelpSection link={ELIGIBILITY_GET_HELP} />
      </main>
      <Footer />
      <Banner
        variant="error"
        show={memberNotEligibleError || eligibilityApiError}
        isBelowNavbar
      >
        <span>
          We were unable to confirm your coverage. Please double check your
          information and try again.&nbsp;
          <a
            href={ELIGIBILITY_GET_HELP}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </span>
      </Banner>
    </div>
  );
}

export const EligibilityConfirmCoverage = observer(
  EligibilityConfirmCoverageComponent,
);
