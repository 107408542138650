import { IS_PROD } from '@environments';
import { omit } from '@utils/omit';
import { pick } from '@utils/pick';
import { applyPatch, IDisposer, IJsonPatch, onSnapshot } from 'mobx-state-tree';

const { sessionStorage } = window;

const persistedProperties = ['onboarding', 'signUp', 'eligibility'];
const omittedProperties = [] as string[];

export const storeKey = 'MemberAppStore';
let disposer: IDisposer;

export function getDisposer() {
  return disposer;
}

// functions to save/load store state from sessionStorage
export const adapter = {
  save: async (snapshot: any) =>
    sessionStorage.setItem(storeKey, JSON.stringify(snapshot)),

  load: async () => {
    const raw = sessionStorage.getItem(storeKey);

    if (raw) {
      return JSON.parse(raw);
    }

    return undefined;
  },
};

export async function applyDataFromSessionStorage(self: any) {
  const data = await adapter.load();

  // apply data stored in sessionStorage
  if (data) {
    if (!IS_PROD) {
      console.log('Loading data snapshot', data); // this logs token
    }

    const patches: IJsonPatch[] = Object.keys(data).map((patchKey) => ({
      op: 'replace',
      path: `/${patchKey}`,
      value: data[patchKey],
    }));

    if (!IS_PROD) {
      console.log('Applying patches', patches); // this logs token
    }

    applyPatch(self, patches);
  }

  applyPatch(self, [
    {
      op: 'replace',
      path: '/bootstrapped',
      value: true,
    },
  ]);

  return data;
}

// filter ignored store properties updates
// so that we only save those that matter
export const filterSnapshotKeys = (snapshot: unknown) => {
  if (!snapshot) {
    return snapshot;
  }

  const only = persistedProperties || [];
  const except = omittedProperties || [];

  // use the input if there's no filters
  if (only.length === 0 && except.length === 0) {
    return snapshot;
  }

  // Assign to whole snapshot if only is empty
  const result =
    only.length > 0 ? pick(snapshot, only as keyof typeof snapshot) : snapshot;

  // Omit some things
  return omit(result, except as keyof typeof result);
};

export const enableSaving = (self: any) => {
  if (disposer) {
    disposer();
    return;
  }
  disposer = onSnapshot(self, (snapshot) =>
    adapter.save(filterSnapshotKeys(snapshot)),
  );
};
