import cn from 'classnames';
import { useAccesibilityEvents } from '@utils/hooks/useAccesibilityEvents';
import { useCallback } from 'react';
import type { SelectOption } from './SelectDropdown';
import styles from './select.module.scss';

type SelectDropdownOptionProps = {
  isSelected: boolean;
  onSelect: (option: SelectOption) => unknown;
} & SelectOption;

export function SelectDropdownOption(props: SelectDropdownOptionProps) {
  const { id, isSelected, label, onSelect, value } = props;

  const handleSelectClick = useCallback(() => {
    onSelect({ id, label, value });
  }, [id, label, onSelect, value]);

  const { onClick, onKeyDown } = useAccesibilityEvents(handleSelectClick);

  return (
    <li id={id}>
      <div
        role="option"
        aria-selected={isSelected}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
        className={cn(styles.option, isSelected && styles.selected)}
      >
        {label}
      </div>
    </li>
  );
}
