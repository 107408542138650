import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SignUp } from '@pages/SignUp';
import { Onboarding } from '@pages/Onboarding';
import SessionTimeout from '@pages/SessionTimeout';
import {
  EligibilityConfirmCoverage,
  EligibilityHome,
  EligibilityEnterAccessCode,
} from '@pages/Eligibility';
import { GetTheApp } from '@pages/GetTheApp';
import { StoreRoute } from './StoreRoute';
import { PrivateRoute } from './PrivateRoute';

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <StoreRoute>
              <SignUp />
            </StoreRoute>
          }
        />
        <Route
          path="/onboarding/*"
          element={
            <StoreRoute>
              <PrivateRoute>
                <Onboarding />
              </PrivateRoute>
            </StoreRoute>
          }
        />
        <Route
          path="/eligibility"
          element={
            <StoreRoute>
              <EligibilityHome />
            </StoreRoute>
          }
        />
        <Route
          path="/eligibility/confirm-coverage"
          element={
            <StoreRoute>
              <EligibilityConfirmCoverage />
            </StoreRoute>
          }
        />
        <Route
          path="/eligibility/enter-access-code"
          element={
            <StoreRoute>
              <EligibilityEnterAccessCode />
            </StoreRoute>
          }
        />
        <Route path="/get-the-app" element={<GetTheApp />} />
        <Route path="/session-timeout" element={<SessionTimeout />} />
      </Routes>
    </BrowserRouter>
  );
}
