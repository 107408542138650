import { useStore } from '@stores/storeProvider';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';

function StoreRouteComponent({ children }: { children: ReactElement }) {
  const store = useStore();

  // TODO splash screen?
  if (!store.bootstrapped) {
    return null;
  }

  return children;
}

export const StoreRoute = observer(StoreRouteComponent);
