// these are the input names that give browser autocomplete cappabilites
// to safetydata form, see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete

export const givenName = 'given-name';
export const familyName = 'family-name';
export const birthday = 'bday';
export const telNational = 'tel-national';
export const addressLine1 = 'address-line1';
export const addressLine2 = 'address-line2';
export const addressLevel1 = 'address-level1';
export const addressLevel2 = 'address-level2';
export const postalCode = 'postal-code';
