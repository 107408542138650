import cn from 'classnames';
import { Button } from '@components/Button';
import { ReactComponent as NextIcon } from '@assets/arrowRight.svg';
import { ComponentProps } from 'react';
import styles from './styles.module.scss';

type Props = ComponentProps<typeof Button> & { className?: string };

export function NextButton(props: Props) {
  return (
    <Button {...props} className={cn(styles.nextButton, props.className)}>
      <NextIcon />
    </Button>
  );
}

NextButton.defaultProps = {
  className: undefined,
};
