import { InputHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './button.module.scss';
import { ButtonProps } from './types';

export function SubmitButton(
  props: ButtonProps & {
    children: string;
  } & InputHTMLAttributes<HTMLInputElement>,
) {
  const { children, variant, className, ...buttonProps } = props;

  const classNames = [
    styles.button,
    variant ? styles[variant] : undefined,
    className,
  ];

  return (
    <input
      className={cn(classNames)}
      type="submit"
      {...buttonProps}
      value={children}
    />
  );
}

SubmitButton.defaultProps = {
  className: undefined,
};
