import {
  AccessCodeValidationError,
  getVerifiedAccessCode,
  InvalidAccessCodeData,
} from '@stores/utils/getVerifiedAccessCode';
import { flow, getRoot, types } from 'mobx-state-tree';
import { isAValidDate } from '@utils/dates';
import { trackEvent } from '@integrations/analytics';
import { browserName } from 'react-device-detect';
import { differenceInYears, format, getYear } from 'date-fns';
import {
  memberCheckEligibility,
  MemberCheckEligibilityParams,
} from '@services/SiblyAPI/platform';
import { isAPIError } from '@services/SiblyAPI/isAPIError';
import type { RootStoreInstance } from '@stores/Root';
import { AccessCode, formatAccessCode } from '../../AccessCode';
import { formatOrganization, Organization } from '../../Organization';

export enum Relationship {
  EMPLOYEE = 'employee',
  SPOUSE = 'spouse',
  DEPENDENT = 'dependent',
}

type Volatile = {
  invalidAccessData?: InvalidAccessCodeData;
  firstNameInputValue: string;
  lastNameInputValue: string;
  birthDateInputValue?: Date;
  attemptNumber: number;
  relationshipToOrganization?: Relationship;
  memberNotEligibleError: boolean;
  eligibilityApiError: boolean;
  isEligible: boolean;
  isValidatingEligibility: boolean;
};

const initialVolatileState: Volatile = {
  firstNameInputValue: '',
  lastNameInputValue: '',
  birthDateInputValue: undefined,
  attemptNumber: 0,
  invalidAccessData: undefined,
  relationshipToOrganization: undefined,
  memberNotEligibleError: false,
  eligibilityApiError: false,
  isValidatingEligibility: false,
  isEligible: false,
};

export const ConfirmEligibilityCoverageModel = types
  .model({
    accessCode: types.maybe(AccessCode),
    organization: types.maybe(Organization),
  })
  .volatile<Volatile>(() => initialVolatileState)
  .views((self) => ({
    get errorName(): string | null {
      if (self.memberNotEligibleError) {
        return 'status not eligible';
      }

      if (self.eligibilityApiError) {
        return 'validation failed';
      }

      return null;
    },
  }))
  .actions((self) => ({
    validateAccessCode: flow(function* validateAccessCode() {
      const rootStore = getRoot(self) as RootStoreInstance;
      const accessCode = rootStore.eligibility
        .selectedEligibilityAccessCode as string;

      try {
        const result = yield getVerifiedAccessCode(accessCode);

        self.accessCode = AccessCode.create(formatAccessCode(result));

        self.organization = Organization.create(
          formatOrganization(result.organization),
        );

        trackEvent('Confirm Coverage Page Displayed', {
          organization: self.organization.name,
          source: 'web',
          browser: browserName,
        });
      } catch (e) {
        if (e instanceof AccessCodeValidationError) {
          self.invalidAccessData = {
            title: e.title,
            subtitle: e.subtitle,
          };
        }
      }
    }),

    validateMemberEligibility: flow(function* validateMemberEligibility() {
      try {
        self.isValidatingEligibility = true;
        self.memberNotEligibleError = false;
        self.eligibilityApiError = false;

        self.attemptNumber += 1;

        if (self.attemptNumber > 1) {
          trackEvent('Try Again Clicked', {
            error: self.errorName,
            source: 'web',
            browser: browserName,
          });
        }

        trackEvent('Eligibility Info Submitted', {
          attemptNumber: self.attemptNumber,
          source: 'web',
          browser: browserName,
        });

        const eligibilityData = {
          dob: format(self.birthDateInputValue!, 'yyyy-MM-dd'),
          firstName: self.firstNameInputValue,
          lastName: self.lastNameInputValue,
          relationshipType: self.relationshipToOrganization!,
        };

        const data: MemberCheckEligibilityParams = {
          organizationId: self.organization?.id!,
          ...eligibilityData,
        };
        const result = yield memberCheckEligibility(data);

        const { isEligibleForSibly } = result;

        if (isEligibleForSibly) {
          trackEvent('Onboarding Eligibility verified', {
            age: differenceInYears(new Date(), self.birthDateInputValue!),
            source: 'web',
            browser: browserName,
          });

          const rootStore = getRoot(self) as RootStoreInstance;

          rootStore.restartSignupState();
          rootStore.signUp.setEligibilityData({
            ...eligibilityData,
            eligibilityAccessCode: self.accessCode?.code!,
          });

          self.isEligible = true;
        } else {
          self.memberNotEligibleError = true;
          trackEvent('Eligibility Error experienced', {
            error: self.errorName,
            source: 'web',
            browser: browserName,
          });
        }
      } catch (e) {
        // api works but process failed
        if (!isAPIError(e)) {
          self.memberNotEligibleError = true;

          // api throws error
        } else {
          self.eligibilityApiError = true;
        }

        trackEvent('Eligibility Error experienced', {
          error: self.errorName,
          source: 'web',
          browser: browserName,
        });
      } finally {
        self.isValidatingEligibility = false;
      }
    }),

    handleDateOfBirthChange(dateSelected: Date) {
      self.birthDateInputValue = dateSelected;
    },

    setFirstName(inputValue: string) {
      self.firstNameInputValue = inputValue;
    },

    setLastName(inputValue: string) {
      self.lastNameInputValue = inputValue;
    },

    selectRelationship(relationshipSelected: Relationship) {
      self.relationshipToOrganization = relationshipSelected;
    },

    resetConfirmEligibilityCoverageState() {
      self.organization = undefined;
      self.accessCode = undefined;
      Object.assign(self, initialVolatileState);
    },
  }))
  .views((self) => ({
    get orgLogo() {
      return self.organization?.logoUrl;
    },
    get orgName() {
      return self.organization?.name;
    },

    get formattedDateOfBirth() {
      return self.birthDateInputValue
        ? format(self.birthDateInputValue, 'MM/dd/yyyy')
        : '';
    },

    get isFirstNameValid() {
      const trimmedName = self.firstNameInputValue.trim();

      return trimmedName.length > 0 && trimmedName.length < 51;
    },

    get firstNameErrorCaption() {
      if (this.isFirstNameValid) return undefined;

      const trimmedName = self.firstNameInputValue.trim();

      if (!trimmedName) return 'First name is required.';
      return 'First name must be 1-50 characters';
    },

    get isLastNameValid() {
      const trimmedLastName = self.lastNameInputValue.trim();

      return trimmedLastName.length > 0 && trimmedLastName.length < 51;
    },

    get lastNameErrorCaption() {
      if (this.isLastNameValid) return undefined;

      const trimmedLastName = self.lastNameInputValue.trim();

      if (!trimmedLastName) return 'Last name is required.';
      return 'Last name must be 1-50 characters';
    },

    get is18OrOlder(): boolean {
      return (
        !!self.birthDateInputValue &&
        isAValidDate(self.birthDateInputValue) &&
        differenceInYears(new Date(), self.birthDateInputValue) >= 18
      );
    },

    get isBirthDateAboveYear1900(): boolean {
      return (
        !!self.birthDateInputValue &&
        isAValidDate(self.birthDateInputValue) &&
        getYear(self.birthDateInputValue) >= 1900
      );
    },

    get isValidDateOfBirth(): boolean {
      return this.is18OrOlder && this.isBirthDateAboveYear1900;
    },

    get birthDateErrorCaption() {
      if (!self.birthDateInputValue) {
        return 'Birthday is required.';
      }
      if (!isAValidDate(self.birthDateInputValue)) {
        return 'You must use MM/DD/YYYY format.';
      }
      // TODO are these needed here?
      if (!this.is18OrOlder) {
        return 'You must be at least 18 years old to sign up for Sibly';
      }
      if (!this.isBirthDateAboveYear1900) {
        return 'Min year is 1900';
      }
      return undefined;
    },

    get isFormSubmitEnabled(): boolean {
      return (
        !self.isValidatingEligibility &&
        this.isFirstNameValid &&
        this.isLastNameValid &&
        this.isValidDateOfBirth &&
        !!self.relationshipToOrganization
      );
    },
    get submitButtonLabel() {
      if (self.isValidatingEligibility) return 'Confirming...';
      if (self.attemptNumber > 0) return 'Retry';
      return 'Next';
    },
  }));
