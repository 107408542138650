import { KeyboardEventHandler, useCallback } from 'react';

export function useAccesibilityEvents(onSelect: Function) {
  // adds keyboard navigation
  const handleKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onSelect(e);
      }
    },
    [onSelect],
  );

  const handleClick = useCallback(
    (e) => {
      onSelect(e);
    },
    [onSelect],
  );

  return {
    onClick: handleClick,
    onKeyDown: handleKeyDown,
  };
}
