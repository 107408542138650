import DateTime from '@utils/mst/DateTime';
import { types, SnapshotIn } from 'mobx-state-tree';

export const AccessCode = types.model({
  id: types.identifierNumber,
  code: types.string,
  isEligibilityCode: types.boolean,
  createdAt: DateTime,
  activatedAt: DateTime,
  deletedAt: DateTime,
  duration: types.maybeNull(types.integer),
});

export type AccessCodeIn = SnapshotIn<typeof AccessCode>;

export const formatAccessCode = <T extends AccessCodeIn>(input: T) => ({
  id: input.id,
  code: input.code,
  isEligibilityCode: input.isEligibilityCode,
  createdAt: input.createdAt,
  activatedAt: input.activatedAt,
  deletedAt: input.deletedAt,
  duration: input.duration,
});
