import { berty } from './berty';
import { env1 } from './env1';
import { env2 } from './env2';
import { env3 } from './env3';
import { env4 } from './env4';
import { env5 } from './env5';
import { env6 } from './env6';
import { env7 } from './env7';
import { env8 } from './env8';
import { env9 } from './env9';
import { env10 } from './env10';

import { local } from './local';
import { qa } from './qa';
import { qa1 } from './qa1';
import { qa2 } from './qa2';

import { prod } from './prod';
import { Environment } from './types';

export const environments = {
  berty,
  env1,
  env2,
  env3,
  env4,
  env5,
  env6,
  env7,
  env8,
  env9,
  env10,
  local,
  qa,
  qa1,
  qa2,
  prod,
};

const envKey = process.env.REACT_APP_ENV as keyof typeof environments;
export const environmentSelected: Environment = environments[envKey];

export const IS_PROD = process.env.REACT_APP_ENV === 'prod';
export const IS_QA = process.env.REACT_APP_ENV === 'qa';

export const appReleaseVersion = process.env.REACT_APP_RELEASE_VERSION;

export const isLocalhost = () =>
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1';
