import { useCallback, useEffect, useRef } from 'react';

// trigger an event if a click event is triggered outside of an element
export function useClickOutside<T extends HTMLElement>(
  clickOutsideListener: () => unknown,
) {
  const elementRef = useRef<T>(null);

  const clickListener = useCallback(
    (e) => {
      // ignore click if it happened inside of element
      if (
        e.target === elementRef.current ||
        elementRef.current?.contains(e.target)
      ) {
        return;
      }

      clickOutsideListener();
    },
    [clickOutsideListener],
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);

    return () => document.removeEventListener('click', clickListener);
  });

  return elementRef;
}
