import { Banner } from '@components/Banner';
import { Button } from '@components/Button';
import { CodeValidationInput } from '@components/CodeValidationInput';
import { trackEvent } from '@integrations/analytics';
import { HelpSection } from '@pages/Eligibility/HelpSection';
import { useStore } from '@stores/storeProvider';
import { PHONE_VERIFICATION_FAQ } from '@utils/constants';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './enter-phone-verification-code.module.scss';

function handleGetHelpClicked() {
  trackEvent('Get Help Clicked', {
    page: 'Enter Verification Code',
    source: 'Onboarding',
    origin: 'web',
  });
}

function EnterPhoneVerificationCodeComponent() {
  const {
    finishOnboarding,
    onboarding: {
      safetyData: { isSafetyFormValid },
      verifyPhone: {
        last4Numbers,
        setVerificationCode,
        sendVerificationCodeWithMethod,
        verifyCode,
        isResendDisabled,
        safetyDataStoredSuccessfully,
        failedToSubmitSafetyDataMessage,
        startResendTimeout,
        verifyCodeButtonLabel,
        resendCode,
        verifyMethodSelected,
        changeMethodButtonlabel,
        failedToSendCode,
      },
    },
  } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    // if safety data is not completed(this can happen if the user reloads site and volatile state is reseted) redirect to fill again
    if (!isSafetyFormValid) {
      navigate('/onboarding/safetyData');
      return;
    }
    // resend is active after 1 minute
    startResendTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (safetyDataStoredSuccessfully) {
      finishOnboarding();

      // redirect to getTheApp screen
      navigate('/get-the-app');
    }
  }, [safetyDataStoredSuccessfully, navigate, finishOnboarding]);

  const handleMethodChangeClick = useCallback(() => {
    const method = verifyMethodSelected === 'call' ? 'sms' : 'call';
    sendVerificationCodeWithMethod(method);
  }, [sendVerificationCodeWithMethod, verifyMethodSelected]);

  return (
    <>
      <Banner show={failedToSendCode} variant="error" isBelowNavbar>
        <span>
          There was an error sending the verification code, please try again
          later or change the verification method
        </span>
      </Banner>
      <div id={styles.enterCodeScreen}>
        <h1 className="header-lg-4 mt-xxs mb-0">Enter Code</h1>
        <p className="text-lg-2 mt-0 mb-md" data-testid="enter-code-content">
          Please enter the 4 digit code sent to the number ending in -
          {last4Numbers}.
        </p>
        <div className={styles.verificationDigits}>
          <CodeValidationInput
            codeLength={4}
            handleCodeChange={setVerificationCode}
            errorCaption={failedToSubmitSafetyDataMessage}
            handleCodeIsComplete={verifyCode}
          />
        </div>
        <div className={styles.changeMethodContainer}>
          <Button variant="link" onClick={handleMethodChangeClick}>
            {changeMethodButtonlabel}
          </Button>
        </div>
        <div className="horizontal-separator" />
        <Button
          variant="primary"
          onClick={resendCode}
          className={styles.resendCodeButton}
          disabled={isResendDisabled}
        >
          {verifyCodeButtonLabel}
        </Button>
      </div>
      <HelpSection
        link={PHONE_VERIFICATION_FAQ}
        onClick={handleGetHelpClicked}
      />
    </>
  );
}

export const EnterPhoneVerificationCode = observer(
  EnterPhoneVerificationCodeComponent,
);
