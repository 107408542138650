import { environmentSelected } from '@environments';
import { ReactElement } from 'react';
import { SplitFactory, useTreatments } from '@splitsoftware/splitio-react';

type SplitTreatments = 'PHONE_VERIFICATION_ENABLED';

type SplitConfig = {
  core: {
    authorizationKey: string;
    key: string;
  };
};

export const getSplitConfig = (): SplitConfig | undefined =>
  environmentSelected.split.key
    ? {
        core: {
          authorizationKey: environmentSelected.split.key,
          key: 'key',
        },
      }
    : undefined;

export function SplitProvider({ children }: { children: ReactElement }) {
  const splitConfig = getSplitConfig();

  return splitConfig ? (
    <SplitFactory config={splitConfig} updateOnSdkUpdate updateOnSdkTimedout>
      {({ isReady }) => (isReady ? children : null)}
    </SplitFactory>
  ) : (
    children
  );
}

export const useSplitBooleanTreatment = (flagName: SplitTreatments) => {
  // evaluate splits with the `useTreatments` hook
  const treatments = useTreatments([flagName]);
  const enabled =
    treatments &&
    treatments[flagName] &&
    treatments[flagName].treatment === 'on';
  // return object in case we add more functionality to the hook
  return { enabled };
};
