/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import { SubmitButton } from '@components/Button/SubmitButton';
import { FieldValidity } from '@utils/forms';
import { MaskedInput } from '@components/MaskedInput';
import { FocusEventHandler, useRef, useState } from 'react';
import styles from './sign-up.module.scss';

// same regex used by Yup library in mobile app
const emailRegex =
  "^((([a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+(\\.([a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(\\\\([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-zA-Z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-zA-Z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))$";

const passwordValidationRules = [
  (password: string) => /[a-zA-Z]+/.test(password),
  (password: string) => /\d+/.test(password),
  (password: string) => password.length >= 8,
];

function validatePasswordRules(password: string) {
  return passwordValidationRules
    .map((rule) => rule(password))
    .every((rule) => rule);
}

type SignUpFormProps = {
  onSubmit: (e: React.SyntheticEvent) => unknown;
};

export function SignUpForm({ onSubmit }: SignUpFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailValidity, setEmailValidity] = useState<FieldValidity>(
    FieldValidity.NOT_VALIDATED,
  );
  const [passwordValidity, setPasswordValidity] = useState<FieldValidity>(
    FieldValidity.NOT_VALIDATED,
  );

  const [isFormValid, setIsFormValid] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  // clean validation on inputs focus
  const focusEmail: FocusEventHandler<HTMLInputElement> = () => {
    setEmailValidity(FieldValidity.NOT_VALIDATED);
  };

  const focusPassword: FocusEventHandler<HTMLInputElement> = () => {
    setPasswordValidity(FieldValidity.NOT_VALIDATED);
  };

  // input validation when they lose focus
  const validateEmail: FocusEventHandler<HTMLInputElement> = ({ target }) => {
    if (!target.value) {
      setEmailValidity(FieldValidity.NOT_VALIDATED);
      return;
    }
    setEmailValidity(
      target.validity.valid ? FieldValidity.VALID : FieldValidity.NOT_VALID,
    );
  };

  const validatePassword: FocusEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    if (!target.value) {
      setPasswordValidity(FieldValidity.NOT_VALIDATED);
      return;
    }
    const isValid = validatePasswordRules(target.value);

    setPasswordValidity(
      isValid ? FieldValidity.VALID : FieldValidity.NOT_VALID,
    );
  };

  // update form validation when any of the inputs changes
  const handleFormChange: React.FormEventHandler<HTMLFormElement> = () => {
    const elements = formRef.current?.elements;
    if (!elements) return;

    const [email, password] = Array.from(elements) as HTMLInputElement[];

    const isEmailValid = email.validity.valid;
    const isPasswordValid = validatePasswordRules(password.value);

    setIsFormValid(isEmailValid && isPasswordValid);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    try {
      if (isSubmitting) {
        e.preventDefault();
        return;
      }
      setIsSubmitting(true);
      await onSubmit(e);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      onChange={handleFormChange}
      className={styles.signUpForm}
      data-testid="signup-form"
      ref={formRef}
    >
      <MaskedInput
        id="email"
        label="Personal Email"
        labelPosition="inner"
        type="email"
        className={styles.emailInput}
        required
        onFocus={focusEmail}
        onBlur={validateEmail}
        validity={emailValidity}
        title="Please provide a valid email adress"
        pattern={emailRegex}
        caption={
          emailValidity === FieldValidity.NOT_VALID
            ? 'Email is required'
            : undefined
        }
      />
      <MaskedInput
        id="password"
        label="Password"
        labelPosition="inner"
        type="password"
        caption="8+ Characters, including 1 letter and 1 number"
        onFocus={focusPassword}
        onBlur={validatePassword}
        validity={passwordValidity}
        required
      />
      <p className={styles.termsAndPrivacy}>
        By clicking “Sign Up & Accept”, you accept Sibly’s{' '}
        <a
          href="https://www.sibly.com/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>{' '}
        &{' '}
        <a
          href="https://www.sibly.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </p>
      <SubmitButton
        variant="primary"
        className={styles.submitButton}
        disabled={!isFormValid || isSubmitting}
        type="submit"
      >
        Sign Up & Accept
      </SubmitButton>
    </form>
  );
}
