import { useState } from 'react';
import cn from 'classnames';
import styles from './accordion.module.scss';
import { ReactComponent as PlusIcon } from './plus.svg';

type AccordionItemProps = {
  id: string;
  title: string;
  content: string;
  className?: string;
};

export function AccordionItem({
  id,
  title,
  content,
  className,
}: AccordionItemProps) {
  const [isContentHidden, setIsContentHidden] = useState(true);

  function toggleIsContentHidden() {
    setIsContentHidden((isHidden) => !isHidden);
  }

  return (
    <div
      className={cn(styles.accordionItem, className)}
      data-testid={`accordion-container-${id}`}
    >
      <div
        className={cn(
          styles.accordionItemHeader,
          isContentHidden && styles.hidden,
        )}
        onClick={toggleIsContentHidden}
        role="button"
        tabIndex={0}
        onKeyDown={toggleIsContentHidden}
      >
        <span
          className={cn(styles.accordionItemTitle, 'text-sm-label-1')}
          data-testid="accordion-title"
        >
          {title}
        </span>
        <PlusIcon className={styles.accordionItemHeaderIcon} />
      </div>
      <div
        className={cn(
          styles.accordionItemContent,
          isContentHidden && styles.hidden,
        )}
        data-testid={`accordion-content-${id}`}
        aria-hidden={isContentHidden}
      >
        <p className="text-lg-2 m-0 pb-md" data-testid="accordion-content">
          {content}
        </p>
      </div>
    </div>
  );
}

AccordionItem.defaultProps = {
  className: undefined,
};
