import { v4 as uuidv4 } from 'uuid';
import { RPCPayload } from './types';

export function buildRpcPayload<Method>(
  method: Method,
  params: any,
): RPCPayload<Method> {
  return {
    method,
    params,
    id: uuidv4(),
    jsonrpc: '2.0',
  };
}
