import { AxiosRequestConfig, Method as RESTMethod } from 'axios';
import { environmentSelected } from '@environments';
import { RESTPath, RESTPayload } from './types';
import { siblyAxios } from './siblyAxiosInstance';
import { headersBuilder, HeadersBuilderOptions } from './headersBuilder';

export type RESTCallParams<Path> = {
  method: RESTMethod;
  path: Path;
  headersOptions?: HeadersBuilderOptions;
  data?: any;
  params?: any;
};

export function restCall(apiTarget: keyof RESTPath) {
  return async ({
    method,
    path,
    headersOptions,
    params,
    data,
  }: RESTCallParams<RESTPath[typeof apiTarget]>) => {
    try {
      const { host } = environmentSelected.sibly[apiTarget];
      const url = `${host}/${path}`;

      const requestConfig: AxiosRequestConfig<
        RESTPayload<typeof method, typeof url>
      > = {};

      requestConfig.method = method;
      requestConfig.url = url;
      requestConfig.headers = headersBuilder(headersOptions);
      requestConfig.params = params;
      requestConfig.data = data;

      const response = await siblyAxios(requestConfig);
      return response.data;
    } catch (error) {
      console.error(`request failed for method: ${method}`, error);
      throw error;
    }
  };
}
