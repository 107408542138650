import { getUrlSearchParam } from '@utils/searchParams';
import { AxiosError } from 'axios';
import { flow, getRoot, types } from 'mobx-state-tree';
import { updateIdentifiedUser, trackEvent } from '@integrations/analytics';
import {
  registerNewMember,
  RegisterNewMemberParams,
} from '@services/SiblyAPI/platform';
import { isAPIError } from '@services/SiblyAPI/isAPIError';
import {
  setEmail,
  setExternalUserId,
} from '@services/OneSignal/OneSignalService';
import { getUserId, setToken } from '@services/TokenProvider';
import { captureSentryException } from '@integrations/sentry';
import FullStoryService from '@integrations/fullStory';

import {
  AccessCodeValidationError,
  getVerifiedAccessCode,
  InvalidAccessCodeData,
} from '@stores/utils/getVerifiedAccessCode';
import type { RootStoreInstance } from '@stores/Root';
import { Relationship } from '../Eligibility/ConfirmEligibilityCoverage';
import { EligibilityModel } from './EligibilityModel';

import { AccessCode, formatAccessCode } from '../AccessCode';
import { Organization, formatOrganization } from '../Organization';

export type EligibilityData = {
  eligibilityAccessCode: string;
  firstName: string;
  lastName: string;
  dob: string;
  relationshipType: Relationship;
};

type VolatileState = {
  invalidAccessData?: InvalidAccessCodeData;
  formError?: string;
  isEligibleMember: boolean;
  eligibilityData?: EligibilityData;
};

type SignUpParameters = {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  relationshipType?: Relationship;
};

export const SignUp = types
  .model({
    accessCode: types.maybe(AccessCode),
    organization: types.maybe(Organization),
    eligibilityData: types.maybe(EligibilityModel),
  })
  .volatile<VolatileState>(() => ({
    isEligibleMember: false,
  }))
  .actions((self) => ({
    wipeEligibilityData() {
      self.eligibilityData = undefined;
    },
    setEligibilityData(data: EligibilityData) {
      self.eligibilityData = data;
    },
    setIsEligibleMember() {
      self.isEligibleMember = true;
    },
    resetSignUpState() {
      self.accessCode = undefined;
      self.organization = undefined;
      self.isEligibleMember = false;
    },
  }))
  .actions((self) => ({
    validateAccessCode: flow(function* validateAccessCode() {
      // sign up with code in url for non eligibility codes
      const accessCodeFromUrl = getUrlSearchParam('access_code');

      // sign up after going through eligibility verification
      const accessCodeFromEligibility =
        self.eligibilityData?.eligibilityAccessCode;

      if (accessCodeFromEligibility) {
        self.setIsEligibleMember();
      }

      const accessCode = accessCodeFromUrl || accessCodeFromEligibility;

      try {
        const result = yield getVerifiedAccessCode(accessCode);

        self.accessCode = AccessCode.create(formatAccessCode(result));

        self.organization = Organization.create(
          formatOrganization(result.organization),
        );

        trackEvent('Sign Up Page Displayed', { accessCode });
      } catch (error) {
        if (error instanceof AccessCodeValidationError) {
          self.invalidAccessData = {
            title: error.title,
            subtitle: error.subtitle,
          };
        }
        captureSentryException(error);
      }
    }),

    registerMember: flow(function* registerMember(data: SignUpParameters) {
      try {
        self.formError = undefined;

        if (!self.accessCode) {
          captureSentryException('access code not defined');
          throw new Error('access code not defined');
        }

        const params: RegisterNewMemberParams = {
          email: data.email,
          password: data.password,
          accessCode: self.accessCode.code,
        };

        // attach eligibility form data if it is set in the store
        if (self.eligibilityData) {
          params.firstName = self.eligibilityData.firstName;
          params.lastName = self.eligibilityData.lastName;
          params.dob = self.eligibilityData.dob;
          params.relationshipType = self.eligibilityData.relationshipType;
        }

        const token = yield registerNewMember(params);

        setToken(token);
        const userId = getUserId();

        if (userId && self.accessCode.code) {
          trackEvent('Account Created', {
            user: userId,
            accessCode: self.accessCode.code,
            // differentiate from mobile app signup
            origin: 'web',
          });

          // set access code as global property in analytics
          updateIdentifiedUser(userId, self.accessCode.code);

          // identify user in fullstory
          FullStoryService.identify(userId.toString());

          // set external user id and email in OneSignal
          setExternalUserId(userId);
          setEmail(data.email);

          const {
            onboarding: {
              setIsOnboardingInProgress,
              setCurrentOnboardingStepIndex,
            },
          } = getRoot<RootStoreInstance>(self);

          // eligibility data is not needed anymore after registering member
          self.wipeEligibilityData();

          // set onboarding flags to trap browser in onboarding routes
          setIsOnboardingInProgress(true);
          setCurrentOnboardingStepIndex(0);
        }
      } catch (error) {
        captureSentryException(error);
        console.error(error);
        // unexpected error not related to Helios request
        if (!isAPIError(error)) {
          self.formError = 'An unexpected error ocurred, please try again.';
          throw error;
        }

        const axiosError = error as AxiosError;
        const requestErrorStatus = axiosError.response?.status;
        // unexpected API error
        if (!requestErrorStatus || requestErrorStatus >= 500) {
          self.formError = 'An unexpected error ocurred, please try again.';
          throw new Error(axiosError.message);
        }

        // email already registered/sign up is disabled
        self.formError = axiosError.response?.data.error.message;
        throw new Error(axiosError.message);
      }
    }),
  }));

export function initSignUpStore() {
  return SignUp.create();
}
