import React from 'react';
import ReactDOM from 'react-dom';
import { appReleaseVersion, environmentSelected as env } from '@environments';
import { getSplitConfig, SplitProvider } from '@integrations/split';
import { addAnalyticsLoadEventListener } from '@integrations/analytics';
import { HelmetProvider } from 'react-helmet-async';
import { StoreProvider } from '@stores/storeProvider';
import { initOneSignal } from '@integrations/oneSignal';
import FullStoryService from '@integrations/fullStory';
import { DEFAULT_BANNER_CONTAINER_ID } from '@components/Banner';
import { Router } from './Router';
import reportWebVitals from './reportWebVitals';
import { SegmentScript } from './scripts/segment';
import './stylesheets/main.scss';

if (env.sentry?.dsn) {
  // import it on demand, to keep dependency out of main js chunk file
  import('@sentry/react').then((Sentry) => {
    Sentry.init({
      dsn: env.sentry.dsn,
      environment: env.name,
      // ex: version 1.0.0 in a development environment would be 'dashboard-dev@1.0.0'
      release: appReleaseVersion
        ? `${env.sentry.project}@${appReleaseVersion}`
        : undefined,
      autoSessionTracking: true,
      // remove console logs from event breadcrumbs, for security reasons
      beforeBreadcrumb(breadcrumb) {
        return breadcrumb.category === 'console' ? null : breadcrumb;
      },
      // returning null will not send event to sentry, ideal for local development of events
      beforeSend: (event) => event,
    });
  });
}

const splitConfig = getSplitConfig();

if (!splitConfig) {
  console.warn('Split.io configuration not provided');
}

addAnalyticsLoadEventListener();

initOneSignal();
FullStoryService.init();

ReactDOM.render(
  <React.StrictMode>
    {/* will be injected in the <head> tag */}
    <HelmetProvider>
      <SegmentScript />

      <SplitProvider>
        <StoreProvider>
          <>
            {/* banners will be rendered inside of this div */}
            <div id={DEFAULT_BANNER_CONTAINER_ID} />
            <Router />
          </>
        </StoreProvider>
      </SplitProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
