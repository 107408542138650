import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { createPortal } from 'react-dom';
import styles from './banner.module.scss';

export const DEFAULT_BANNER_CONTAINER_ID = 'banner-container';

type BannerProps = PropsWithChildren<{
  show: boolean;
  containerElementId?: string;
  variant?: 'info' | 'success' | 'error';
  className?: string;
  isBelowNavbar?: boolean;
}>;

export function Banner(props: BannerProps) {
  const {
    show,
    containerElementId,
    variant,
    className,
    children,
    isBelowNavbar,
  } = props;
  const containerId = containerElementId || DEFAULT_BANNER_CONTAINER_ID;

  const containerElement = document.getElementById(containerId);

  if (!containerElement) {
    console.error('There is no container element for the banner component');
    return null;
  }

  return show
    ? createPortal(
        <div
          className={cn(
            styles.banner,
            variant && styles[variant],
            isBelowNavbar && styles.isBelowNavbar,
            className,
          )}
          role="alert"
        >
          {children}
        </div>,
        containerElement,
      )
    : null;
}

Banner.defaultProps = {
  containerElementId: undefined,
  variant: 'error',
  className: undefined,
  isBelowNavbar: false,
};
