import { captureSentryException } from '@integrations/sentry';
import { isAPIError } from '@services/SiblyAPI/isAPIError';
import { accessCodeVerifyRPC } from '@services/SiblyAPI/platform';
import { AxiosError } from 'axios';

export type InvalidAccessCodeData = {
  title: string;
  subtitle: string;
};

export class AccessCodeValidationError extends Error {
  title: string;

  subtitle: string;

  constructor(title: string, subtitle: string) {
    super(title);
    this.name = 'AccessCodeValidationError';
    this.title = title;
    this.subtitle = subtitle;
  }
}

export async function getVerifiedAccessCode(code: string | undefined) {
  try {
    if (!code) throw new Error('Access Code not provided');

    const result = await accessCodeVerifyRPC(code);

    return result;
  } catch (error) {
    captureSentryException(error);

    // access code not provided
    if (!isAPIError(error)) {
      throw new AccessCodeValidationError(
        'This url is invalid',
        'Talk to your access code provider to get a valid signup url.',
      );
    }

    const axiosError = error as AxiosError;

    // code expired OR code not active OR reached limit
    if (axiosError.response?.status === 400) {
      throw new AccessCodeValidationError(
        'Access Code Error',
        axiosError.response?.data?.error?.message,
      );
    }

    // invalid access code
    if (axiosError.response?.status === 404) {
      throw new AccessCodeValidationError(
        'Invalid access code',
        'Talk to your access code provider to get a valid signup url.',
      );
    }

    // unexpected error(500s)
    throw new AccessCodeValidationError(
      'Failed to validate access code',
      'Please try again later.',
    );
  }
}
