import { MouseEventHandler } from 'react';

import { ReactComponent as HidePasswordSvg } from '../assets/hidePassword.svg';
import { ReactComponent as ViewPasswordSvg } from '../assets/viewPassword.svg';
import styles from '../input.module.scss';

export function ViewPasswordIcon(props: {
  viewPassword: boolean;
  onClick: MouseEventHandler;
}) {
  return props.viewPassword ? (
    <ViewPasswordSvg
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      aria-label="view password button"
      className={styles.passwordIcon}
    />
  ) : (
    <HidePasswordSvg
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      aria-label="hide password button"
      className={styles.passwordIcon}
    />
  );
}
