import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as axiosLogger from 'axios-logger';

// const defaultNetworkTimeout = 4000;

export const siblyAxios = axios.create({});

axiosRetry(siblyAxios, { retries: 3 });

siblyAxios.interceptors.request.use(
  axiosLogger.requestLogger,
  axiosLogger.errorLogger,
);
siblyAxios.interceptors.response.use(
  axiosLogger.responseLogger,
  axiosLogger.errorLogger,
);
