import { ReactComponent as SiblyLogo } from '@assets/sibly-logo.svg';
import styles from './navbar.module.scss';

export function Navbar() {
  return (
    <nav className={styles.navbar}>
      <SiblyLogo className={styles.logo} />
    </nav>
  );
}
