import { Accordion } from '@components/Accordion';
import cn from 'classnames';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useStore } from '@stores/storeProvider';
import { observer } from 'mobx-react-lite';
import { trackEvent } from '@integrations/analytics';
import {
  SafetyDataInputName,
  safetyDataStateInputName,
} from '@common/SafetyDataForm/utils';
import { SafetyDataForm } from '@common/SafetyDataForm';
import { useSplitBooleanTreatment } from '@integrations/split';
import { useNavigate } from 'react-router-dom';
import styles from '../onboarding.module.scss';

const accordionItems = [
  {
    id: 'why-does-sibly-need-my-personal-information',
    title: 'Why does Sibly need my personal information?',
    content:
      'We collect personal information for your safety and the safety of those around you. If legally required, Sibly will use this information to keep you and others safe, including to report the abuse of children, elders and disabled persons.',
  },
  {
    id: 'will-my-personal-information-be-kept-private',
    title: 'Will my personal information be kept private?',
    content:
      'Your personal information will only be used, as required by law, to protect your safety and the safety of others around you. Your personal information will not be shared with the Sibly coaches you correspond with. If Sibly is a benefit provided to you by your employer or another sponsoring organization, your private information and the content of the messages you exchange with Sibly will not be shared with your employer or sponsor.',
  },
];

type SafetyDataComponentProps = {
  goToNextStep: () => void;
};

function SafetyDataComponent({ goToNextStep }: SafetyDataComponentProps) {
  const {
    finishOnboarding,
    onboarding: {
      safetyData: {
        safetyDataForm,
        setInputValue,
        isSafetyFormValid,
        nameErrorCaption,
        birthDateErrorCaption,
        cityErrorCaption,
        stateErrorCaption,
        zipCodeErrorCaption,
        addressErrorCaption,
        safetyFormError,
        phoneNumberErrorCaption,
        submitContactDetails,
        isSavingContactDetails,
      },
    },
  } = useStore();

  const navigate = useNavigate();

  const { enabled: isPhoneVerificationEnabled } = useSplitBooleanTreatment(
    'PHONE_VERIFICATION_ENABLED',
  );

  useEffect(() => {
    trackEvent('Safety Info Page Displayed');
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === safetyDataStateInputName) {
      e.target.value = e.target.value.toUpperCase();
    }
    setInputValue(e.target.name as SafetyDataInputName, e.target.value);
  };

  const handleFormSubmit: React.FormEventHandler = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        // if phone verification is enabled data will be submitted after going through phone verification process
        if (isPhoneVerificationEnabled) {
          goToNextStep();
          return;
        }

        // otherwise submit contact details to safetydata api
        // and redirect to getTheApp screen
        await submitContactDetails();

        finishOnboarding();

        navigate('/get-the-app');
      } catch (error) {
        console.error(error);
      }
    },
    [
      isPhoneVerificationEnabled,
      submitContactDetails,
      finishOnboarding,
      navigate,
      goToNextStep,
    ],
  );

  return (
    <>
      <h1 className="header-lg-4 mt-xxs mb-0">Contact Details</h1>
      <p className="text-lg-2 mt-0 mb-md" data-testid="safety-matters-content">
        To comply with safety laws, please enter your full legal name, date of
        birth and physical address. This will be kept secure and will not be
        visible to your coaching team.
      </p>
      <SafetyDataForm
        data={safetyDataForm}
        handleFormSubmit={handleFormSubmit}
        handleInputChange={handleInputChange}
        addressErrorCaption={addressErrorCaption}
        birthDateErrorCaption={birthDateErrorCaption}
        cityErrorCaption={cityErrorCaption}
        nameErrorCaption={nameErrorCaption}
        isSafetyFormValid={isSafetyFormValid}
        stateErrorCaption={stateErrorCaption}
        zipCodeErrorCaption={zipCodeErrorCaption}
        safetyFormError={safetyFormError}
        includePhoneNumberField={!isPhoneVerificationEnabled}
        phoneNumberErrorCaption={phoneNumberErrorCaption}
        isSavingSafetyForm={isSavingContactDetails}
      />
      <h3 className="title-lg-1 mt-xl mb-lg">Frequently Asked Questions</h3>
      <Accordion items={accordionItems} />
      <h4 className={cn('text-sm-label-1 mt-xl', styles.reachOut)}>
        Still have questions? Please reach out to{' '}
        <a href="mailto:care@sibly.com">care@sibly.com</a>
      </h4>
    </>
  );
}

export const SafetyData = observer(SafetyDataComponent);
