import { InputHTMLAttributes } from 'react';
import styles from './radio-input.module.scss';

type Props = {
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function RadioInput(props: Props) {
  const { label, ...radioProps } = props;
  return (
    <label htmlFor={props.id} className={styles.radioLabel}>
      <input type="radio" {...radioProps} className={styles.radioInput} />
      <span>{label}</span>
    </label>
  );
}
