import { Input } from '@components/Input';
import { NextButton } from '@components/NextButton';
import { Toast } from '@components/Toast';
import { useStore } from '@stores/storeProvider';
import { observer } from 'mobx-react-lite';
import { ChangeEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './eligibility-home.module.scss';

function AccessCodeSectionComponent() {
  const {
    eligibility: {
      accessCodeInputValue,
      setAccessCodeInputValue,
      trackAccessCodeEntered,
      validateAccessCodeEntered,
      showInvalidAccessCodeError,
    },
  } = useStore();
  const navigate = useNavigate();

  const handleAccessCodeChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const {
          target: { value },
        } = e;

        setAccessCodeInputValue(value);
      },
      [setAccessCodeInputValue],
    );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      trackAccessCodeEntered();
      const isValid = await validateAccessCodeEntered();
      if (isValid) {
        navigate(`/?access_code=${accessCodeInputValue}`);
      }
    },
    [
      accessCodeInputValue,
      navigate,
      trackAccessCodeEntered,
      validateAccessCodeEntered,
    ],
  );

  return (
    <section
      className={styles.accessCodeSection}
      data-testid="access-code-section"
    >
      {showInvalidAccessCodeError && (
        <Toast
          variant="error"
          text="Access code entered is not valid."
          className={styles.accessCodeToast}
        />
      )}
      <form onSubmit={handleSubmit} className={styles.accessCodeForm}>
        <Input
          id="access-code"
          label="Enter access code"
          labelPosition="top"
          placeholder="ex: ABC123"
          value={accessCodeInputValue}
          onChange={handleAccessCodeChange}
          className={styles.accessCodeInput}
        />
        <NextButton
          variant="primary"
          disabled={!accessCodeInputValue}
          onClick={handleSubmit}
          type="submit"
        />
      </form>
    </section>
  );
}

export const AccessCodeSection = observer(AccessCodeSectionComponent);
