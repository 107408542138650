import cn from 'classnames';
import { Footer } from '@components/Footer';
import { Navbar } from '@components/Navbar';
import { NextButton } from '@components/NextButton';
import { useBreakpoints } from '@utils/hooks/useBreakpoints';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/storeProvider';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaskedInput } from '@components/MaskedInput';
import { UNMASK_CLASSNAME } from '@common/FullstoryMask/FullStoryMask';
import { ELIGIBILITY_GET_HELP } from '@utils/constants';
import { HelpSection } from '../HelpSection';
import styles from './enter-access-code.module.scss';
import MemberIllustration from './assets/member_illustration.png';

function EligibilityEnterAccessCodeComponent() {
  const { isDesktop } = useBreakpoints();
  const navigate = useNavigate();

  const {
    enterAccessCodeEligiblity: {
      accessCodeInputValue,
      setAccessCodeInputValue,
      resetAccessCodeValue,
      isNextButtonDisabled,
    },
    restartSignupState,
  } = useStore();

  useEffect(() => {
    resetAccessCodeValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      restartSignupState();
      navigate(`/?access_code=${accessCodeInputValue}`);
    },
    [accessCodeInputValue, navigate, restartSignupState],
  );

  const handleInputChange = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;

      setAccessCodeInputValue(value);
    },
    [setAccessCodeInputValue],
  );

  return (
    <div
      className={cn(styles.enterAccessCode, UNMASK_CLASSNAME)}
      data-testid="eligibility-enter-code-page"
    >
      <Navbar />
      <main className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          <h3 className={styles.contentHeader}>Get Started</h3>
          <p className={styles.contentDescription}>
            Enter the code (ex. ABC123) provided to you by your covering
            organization to verify your access.
          </p>
          <form className={styles.accessCodeForm} onSubmit={handleSubmit}>
            <MaskedInput
              id="enter-access-code-input"
              label="Enter access code"
              labelPosition="top"
              placeholder="ex: ABC123"
              value={accessCodeInputValue}
              onChange={handleInputChange}
              className={styles.accessCodeInput}
            />
            <NextButton
              type="submit"
              variant="primary"
              data-testid="submit-button"
              className={styles.nextButton}
              disabled={isNextButtonDisabled}
            />
          </form>
          <div className={styles.helpSectionContainer}>
            <HelpSection link={ELIGIBILITY_GET_HELP} />
          </div>
        </div>
        {isDesktop && (
          <div
            className={styles.backgroundContainer}
            data-testid="background-illustration"
          >
            <img src={MemberIllustration} alt="member illustration" />
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
}

export const EligibilityEnterAccessCode = observer(
  EligibilityEnterAccessCodeComponent,
);
