export function formatNumber(phoneNumber: string) {
  // extract all numbers from input value
  // and join them in a string
  const numberGroups = phoneNumber.match(/\d+/g);
  if (!numberGroups) return phoneNumber;
  const joinned = numberGroups.join('');

  // for `12` returns `12`
  if (joinned.length < 3) return joinned;

  // for 123 returns (123)
  // for 1234 returns (123) 4
  if (joinned.length < 6)
    return `(${joinned.substring(0, 3)}) ${joinned.substring(3, 6)}`;

  // for 1234567890 returns (123) 456-7890
  return `(${joinned.substring(0, 3)}) ${joinned.substring(
    3,
    6,
  )}-${joinned.substring(6)}`;
}
