import { ReactComponent as ClearInput } from '@assets/closeInput.svg';
import { useAccesibilityEvents } from '@utils/hooks/useAccesibilityEvents';
import { MouseEventHandler } from 'react';
import styles from './clear-input-button.module.scss';

type Props = {
  onClear: MouseEventHandler;
};

export function ClearInputButton({ onClear }: Props) {
  const { onClick, onKeyDown } = useAccesibilityEvents(onClear);

  return (
    <ClearInput
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
      className={styles.clearInputButton}
      aria-label="clear input"
    />
  );
}
